import { ethers } from "ethers";
import axios from "axios";
import { ERC20_ABI, ERC1155_ABI } from "../abi";
import { NETWORK_CONSTANT } from "../constant";
import type { uniswapTokenItem } from "../type";
import { ENDPOINT, INTERFACE_ID, KEY_STORE } from "../constant";

export default class ContractHelper {
  /**
   * 
   * @param chainId 
   * @returns token list
   */
  static async getTokenList(chainId: number | undefined) {
    try {
      const response = await axios.get(ENDPOINT.UNISWAP_TOKEN_LIST, {
        // headers: {
        //   'Access-Control-Allow-Origin': '*',
        //   // 'Access-Control-Allow-Headers': '*',
        //   'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT'
        // }
      })
      const tokens: Array<uniswapTokenItem> = response.data.tokens;

      const list = tokens.filter(token => (token.chainId === chainId))

      return list
    }
    catch (error) {
      throw error
    }
  }

  /**
   * 
   * @param address 
   * @param chainId 
   * @returns token detail (name, symbol, decimals)
   */
  static async getTokenDetail(address: string, chainId: number) {
    if (!this.isContractAddress(chainId, address)) {
      throw new Error("Invalid contract address")
    }

    try {
      const rpc_url: string = NETWORK_CONSTANT.CHAINID_TO_RPC[chainId];
      const provider = new ethers.JsonRpcProvider(rpc_url)
      const contract = new ethers.Contract(address, ERC20_ABI, provider)
      const networkOrigin = (await provider.getNetwork()).name
      const logoURI = `https://assets-cdn.trustwallet.com/blockchains/${networkOrigin}/assets/${address}/logo.png`

      const [name, symbol, decimals] = await Promise.all([
        contract.name() || contract._name(),
        contract.symbol() || contract._symbol(),
        contract.decimals() || contract._decimals(),
      ])

      return { chainId, address, name, symbol, decimals, logoURI }
    } catch (error) {
      throw error
    }
  }

  static async checkBalance(chainId: number, tokenAddress: string, address: string) {
    try {
      const rpc_url: string = NETWORK_CONSTANT.CHAINID_TO_RPC[chainId];
      const provider = new ethers.JsonRpcProvider(rpc_url)
      const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider)
      const balance = await contract.balanceOf(address)

      return balance
    }
    catch (error: any) {
      throw error
    }
  }

  static async isContractAddress(chainId: number, address: string) {
    const rpc_url: string = NETWORK_CONSTANT.CHAINID_TO_RPC[chainId];
    const provider = new ethers.JsonRpcProvider(rpc_url)
    try {
      const code = await provider.getCode(address)
      if (code !== '0x') return true
      else return false
    } catch {
      return false
    }
  }

  static async isERC1155(chainId: number, address: string) {
    const rpc_url: string = NETWORK_CONSTANT.CHAINID_TO_RPC[chainId];
    const provider = new ethers.JsonRpcProvider(rpc_url)
    const contract = new ethers.Contract(address, ERC1155_ABI, provider)

    try {
      const interfaceSupported = await contract.supportsInterface(INTERFACE_ID.ERC1155);
      if (interfaceSupported) return true
      else return false
    }
    catch {
      return false
    }
  }

  static async getERC1155Info(chainId: number, contractAddress: string, address: string, id: number) {
    const rpc_url: string = NETWORK_CONSTANT.CHAINID_TO_RPC[chainId];
    const provider = new ethers.JsonRpcProvider(rpc_url)
    const contract = new ethers.Contract(contractAddress, ERC1155_ABI, provider)

    let balance: any, uri: string;

    try {
      balance = await contract.balanceOf(address, id);
    } catch {
      balance = 0;
    }

    try {
      uri = await contract.uri(id);
    } catch {
      uri = "";
    }

    return {
      balance, uri
    }
  }
}