import React from "react";
import { AggregationCryptoExchangeWidget } from "../components/widget";

interface WidgetFrameProps {
    width?: string;
    height?: string;
    appId?: string;
    buyToken?: string;
    domain?: string;
}

const AggregationCryptoExchangeWidgetFrame: React.FC<WidgetFrameProps> = ({ width, height, appId, buyToken, domain }) => {

    return (
        <div className={`bg-[#ffffff] w-[${!!width ? width : '100%'}] h-[${!!height ? height : '100dvh'}]`}>
            <div className="flex flex-col w-full h-[100dvh]">
                <AggregationCryptoExchangeWidget appId={appId} buyToken={buyToken} domain={domain} />
            </div>
        </div>
    );
};

export default AggregationCryptoExchangeWidgetFrame;