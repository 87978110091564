import React, { useEffect, useState } from "react";
import { AiOutlineSwap } from "react-icons/ai";
import { useWidget } from "../context/WidgetContext";
import { ROOT_STYLE, ROLE, MESSAGE } from "../constant";
import TokenInput from "../components/TokenInput";
import TopSettingBar from "../components/TobSettingBar";
import Banner from "../components/Banner";
import Swal from "sweetalert2";
import LoadingIcon from "react-loading-icons";

import InvalidAccesskeyFrame from "../../../pages/InvalidAccessKey";

import { Buffer } from "buffer";
import { tokenItem, transaction } from "../type";

import { AggregatorSwapAction, PaymentAction } from "../action";
import { ethers } from "ethers";
import { useAccount, useNetwork } from "wagmi";
import { useAccountModal } from "@rainbow-me/rainbowkit";

import type { quote } from "../type";

import { Util } from "../helper";

window.Buffer = window.Buffer || Buffer;

interface AggregationCryptoExchangeWidgetPropTypes {
    appId?: string;
    removeLogo?: boolean;
    buyToken?: string;
    domain?: string
}

const AggregationCryptoExchangeWidget: React.FC<AggregationCryptoExchangeWidgetPropTypes> = ({
    appId,
    buyToken,
    domain
}) => {
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { slippage } = useWidget();
    const { accountModalOpen } = useAccountModal()

    const [userRole, setUserRole] = useState(ROLE.AGGREGATOR_SWAP.REMOVE_LOGO_ROLE)

    const [quotes, setQuotes] = useState<quote[]>([]);
    const [quote, setQuote] = useState<quote | null>(null)
    const [swapTransaction, setSwapTransaction] = useState<transaction[]>([]);
    const [inputToken, setInputToken] = useState<tokenItem>();
    const [outputToken, setOutputToken] = useState<tokenItem>();
    const [inputAmount, setInputAmount] = useState<string>("");
    const [outputAmount, setOutputAmount] = useState<string>("");

    const [actionLoading, setActionLoading] = useState<boolean>(false);
    const [swapping, setSwapping] = useState<boolean>(false)

    const [focusedOn, setFocusedOn] = useState<"input" | "output">("input");
    const [quoteFor, setQuoteFor] = useState<"input" | "output">("output")

    const onFocusOnInput = () => setFocusedOn("input");

    const onFocusOnOutput = () => setFocusedOn("output");

    const handleTokenInput = (token: tokenItem) => {
        setInputToken(token);
        setInputAmount("0")
        setOutputAmount("0")
        setQuotes([])
    }

    const handleTokenOutput = (token: tokenItem) => {
        setOutputToken(token);
        setOutputAmount("0")
        setQuotes([])
    }

    const handleAmountInput = (amount: string) => {
        setInputAmount(amount);
        setOutputAmount("0")
        setQuotes([])
    }

    const handleAmountOutput = (amount: string) => setOutputAmount(amount);

    const handleQuoteSelect = async (dexName: string) => {
        const quote = quotes.filter(quote => quote.dexName === dexName)[0]
        setQuote(quote)
        if (quoteFor === "output") {
            if (typeof quote.toTokenAmount === "string") {
                setOutputAmount(ethers.formatUnits(Util.toFixed(quote.toTokenAmount), quote.toToken.decimals))
            }
            else {
                setOutputAmount((parseInt(quote.toTokenAmount?.hex, 16) / 10 ** (quote.toToken.decimals || 18)).toFixed(4).toString())
            }
        }
        else {
            if (typeof quote.toTokenAmount === "string") {
                setInputAmount(ethers.formatUnits(Util.toFixed(quote.toTokenAmount), quote.toToken.decimals))
            }
            else {
                setInputAmount((parseInt(quote.toTokenAmount?.hex, 16) / 10 ** (quote.toToken.decimals || 18)).toFixed(4).toString())
            }
        }
    }

    const handleSwapDirection = () => {
        let temporaryToken: tokenItem | undefined;
        let temporaryAmount: string;

        temporaryToken = inputToken;
        temporaryAmount = inputAmount;

        setInputToken(outputToken);
        setOutputToken(temporaryToken);

        setInputAmount(outputAmount);
        setOutputAmount(temporaryAmount);
    };

    const clearSwap = () => {
        setInputToken(undefined)
        setOutputToken(undefined)
        setInputAmount("0")
        setOutputAmount("0")
        setQuotes([])
    }

    const onFetchQuote = async () => {
        if (!address) {
            Swal.fire("Connection error", MESSAGE.ERROR.FORCE_CONNECT, "error");
            return;
        }

        if (!inputToken || !outputToken) {
            Swal.fire("Invalid input", MESSAGE.ERROR.FORCE_SELECT_TOKEN, "error");
            return;
        }

        if (
            (focusedOn === "input" && (isNaN(Number(inputAmount)) || !inputAmount)) ||
            (focusedOn === "output" && (isNaN(Number(outputAmount)) || !outputAmount))
        ) {
            Swal.fire(
                "Invalid input",
                MESSAGE.ERROR.FORCE_INPUT_NUMERICAL_VALUE,
                "error"
            );
            return;
        }

        if(Number(inputAmount) <= 0) {
            Swal.fire(
                "Invalid input",
                MESSAGE.ERROR.FORCE_INPUT_AMOUNT,
                "error"
            );
            return;
        }

        // if (focusedOn === "input") {
        try {
            setActionLoading(true);

            const data = await AggregatorSwapAction.fetchQuote(
                chain?.id!,
                inputToken?.address,
                outputToken?.address,
                ethers.parseUnits(inputAmount, inputToken.decimals).toString()
            );

            console.log("onFetchQuote() ==> data fetched", data)
            
            if(data.length > 0) {
                setQuote(data[0])
    
                if (typeof data[0].toTokenAmount === "string") {
                    setOutputAmount(ethers.formatUnits(Util.toFixed(data[0].toTokenAmount), data[0].toToken.decimals))
                }
                else {
                    setOutputAmount((parseInt(data[0].toTokenAmount?.hex, 16) / 10 ** (outputToken?.decimals || 18)).toFixed(4).toString())
                }
            }
            else {
                Swal.fire("Oops...", MESSAGE.ERROR.NO_PAIR, "warning")
            }

            setQuotes(data)
            setQuoteFor("output")

            setActionLoading(false);
        } catch (error) {
            console.error(error)
            setActionLoading(false);
            Swal.fire("Action failed", MESSAGE.ERROR.FAILED_FETCH_SWAP_DATA, "error");
        }
        // } else {
        //     try {
        //         setActionLoading(true);

        //         const data = await AggregatorSwapAction.fetchQuote(
        //             chain?.id || 1,
        //             outputToken?.address,
        //             inputToken?.address,
        //             ethers.parseUnits(outputAmount, outputToken.decimals).toString()
        //         );

        //         if (data.length === 1) {
        //             setQuote(data[0])
        //             if (typeof data[0].toTokenAmount === "string") {
        //                 setInputAmount(ethers.formatUnits(Util.toFixed(data[0].toTokenAmount), data[0].toToken.decimals))
        //             }
        //             else {
        //                 setInputAmount((parseInt(data[0].toTokenAmount?.hex, 16) / 10 ** (inputToken?.decimals || 18)).toFixed(4).toString())
        //             }
        //         }
        //         setQuotes(data)
        //         setQuoteFor("input")

        //         console.log(data)

        //         setActionLoading(false);
        //     } catch (error) {
        //         setActionLoading(false);
        //         Swal.fire("Action failed", MESSAGE.ERROR.FAILED_FETCH_SWAP_DATA, "error");
        //     }
        // }
    };

    const handleExecuteSwapTransaction = async () => {
        Swal.fire({
            icon: "success",
            title: "Please confirm this transaction in your wallet.",
            showDenyButton: true,
            confirmButtonText: "Confirm",
        }).then(async (result) => {
            if (result.isConfirmed) {
                await executeSwapTransaction()
            } else if (result.isDenied) {
                return
            }
        });
    }

    const executeSwapTransaction = async () => {
        if (!address) {
            Swal.fire("Connection error", MESSAGE.ERROR.FORCE_CONNECT, "error");
            return;
        }

        if (!inputToken || !outputToken) {
            Swal.fire("Invalid input", MESSAGE.ERROR.FORCE_SELECT_TOKEN, "error");
            return;
        }

        if (!quote) {
            Swal.fire("Invalid input", MESSAGE.ERROR.FORCE_SELECT_QUOTE, "error")
            return;
        }

        try {
            setSwapping(true)

            Swal.fire({
                title: "Please confirm this transaction in your wallet.",
                icon: "success"
            })

            const swapTransaction = await AggregatorSwapAction.fetchSwapTransaction(
                chain?.id!, // chainId
                inputToken?.address, // inputAddress
                outputToken?.address, // outputAddress
                address, // from address
                ethers.parseUnits(inputAmount, inputToken?.decimals).toString(), // amount
                slippage, // slippage
                quote?.dexName // dexName
            );

            console.log("swapTransaction", swapTransaction);

            setSwapTransaction(swapTransaction);

            try {
                const provider = new ethers.BrowserProvider(window?.ethereum!);
                const signer = provider.getSigner();

                for await (const transaction of swapTransaction) {
                    try {
                        const sent = await (await signer).sendTransaction(transaction);
                        const confirmed = await sent.wait();
                        console.log(confirmed);
                    } catch (error) {
                        Swal.fire(
                            "Action failed",
                            "The transaction was not successed. Check your balance is enough",
                            "error"
                        );
                        console.log(error);
                        throw error;
                    }
                }
                Swal.fire(
                    "Successful Transaction!",
                    `${inputAmount} ${inputToken?.symbol} exchanged to ${outputAmount} ${outputToken?.symbol}`,
                    "success"
                );

                clearSwap()
            } catch (error) {
                Swal.fire("Action failed", MESSAGE.ERROR.FAILED_RUN_SWAP_DATA, "error");
                console.log(error);
            } finally {
                setSwapping(false)
            }
        }
        catch (error) {
            Swal.fire("Action failed", MESSAGE.ERROR.FAILED_FETCH_SWAP_DATA, "error");
            console.error(error)
        }
        finally {
            setSwapping(false)
        }
    };

    const fetchAndExecuteAddLiquidityTranasction = async () => {
        try {
            setQuotes([])

            const bigNumberInputAmount = ethers
                .parseUnits(inputAmount.toString(), inputToken?.decimals)
                .toString();
            const bigNumberOutputAmount = ethers
                .parseUnits(outputAmount.toString(), outputToken?.decimals)
                .toString();
            const transactions = await AggregatorSwapAction.fetchAddLiquidityTransaction(
                chain?.id,
                inputToken?.address,
                outputToken?.address,
                address?.toString(),
                bigNumberInputAmount,
                bigNumberOutputAmount,
                appId
            );

            try {
                setActionLoading(true);

                const provider = new ethers.BrowserProvider(window?.ethereum!);
                const signer = provider.getSigner();
                for await (const transaction of transactions) {
                    try {
                        const sent = await (await signer).sendTransaction(transaction);
                        const confirmed = await sent.wait();
                        console.log(confirmed);
                    } catch (error) {
                        throw error;
                    }
                }
                Swal.fire(
                    "Successful Transaction!",
                    `${inputAmount} ${inputToken?.symbol} and${outputAmount} ${outputToken?.symbol} added liquidity`,
                    "success"
                );
                setActionLoading(false);
            } catch (error) {
                Swal.fire(
                    "Action failed",
                    "The transaction execution was not successed",
                    "error"
                );
                console.error("fetchAndExecuteAddLiquidityTranasction() ==>", error);
            } finally {
                setActionLoading(false);
            }
        } catch (error) {
            Swal.fire(
                "Action failed",
                `Select a pair, review, then add liquidity to Fanbase Pools at the current rate.`,
                "error"
            );
            console.error("fetchAndExecuteAddLiquidityTranasction() ==>", error);
        }
    };

    // useEffect(() => {
    //     (async () => {
    //         if (chain?.id) {
    //             try {
    //                 if (!!appId) {
    //                     const role = await PaymentAction.verifyHashkey(chain.id, appId, domain || "")
    //                     console.log(role.data)
    //                     setUserRole(role.data)
    //                 }
    //                 else {
    //                     setUserRole(ROLE.AGGREGATOR_SWAP.BLOCK_ROLE)
    //                 }
    //             }
    //             catch (error) {
    //                 console.error(error)
    //                 setUserRole(ROLE.AGGREGATOR_SWAP.BLOCK_ROLE)
    //             }
    //         }
    //     })()
    // }, [chain?.id])

    /**
     * aaccount modal hook
     */
    useEffect(() => {
        if (accountModalOpen) {
            Swal.fire(MESSAGE.TITLE.CHANGE_ACCOUNT, MESSAGE.INFO.CHANGE_ACCOUNT, "info")
        }
    }, [accountModalOpen])

    /**
     * account change hook
     */
    useEffect(() => {
        clearSwap()
    }, [address, chain])

    /**
     * Detect network change...
     */
    useEffect(() => {
        setInputToken(undefined)
        setOutputToken(undefined)

        setInputAmount("")
        setOutputAmount("")
    }, [chain?.id])
    return (
        <>
            {
                userRole >= ROLE.AGGREGATOR_SWAP.RENDER_ROLE ?
                    <div className="flex flex-col w-full h-[100dvh] bg-white justify-between justify-items-center rounded-xl text-[#263238] p-6">
                        <TopSettingBar title="Aggregate Swap" />

                        <TokenInput
                            label="You send"
                            token={inputToken}
                            onTokenSelected={handleTokenInput}
                            onAmountChange={handleAmountInput}
                            amount={inputAmount}
                            symbol={inputToken?.symbol}
                            logo={inputToken?.logo}
                            onFocus={onFocusOnInput}
                            quotes={quoteFor === "input" ? quotes : undefined}
                            onQuoteSelect={handleQuoteSelect}
                        />

                        <button
                            onClick={handleSwapDirection}
                            className="flex w-[15%] h-[8dvh] items-center justify-center text-align-center justify-items-center mx-auto rounded-md bg-white px-6 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            <AiOutlineSwap className="flex" size={'70%'} />
                        </button>

                        <TokenInput
                            disabled
                            label="You receive"
                            token={outputToken}
                            onTokenSelected={handleTokenOutput}
                            onAmountChange={handleAmountOutput}
                            amount={outputAmount}
                            symbol={outputToken?.symbol}
                            logo={outputToken?.logo}
                            onFocus={onFocusOnOutput}
                            quotes={quoteFor === "output" ? quotes : undefined}
                            onQuoteSelect={handleQuoteSelect}
                            limited={userRole >= ROLE.AGGREGATOR_SWAP.UNLIMITED_TOKEN_INPUT_ROLE}
                            fixedToken={buyToken}
                            to
                        />

                        <div className="flex items-center justify-between mb-2 mt-4">
                            <button
                                className="w-[49%] h-[10dvh] flex items-center justify-center shrink-0 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                onClick={onFetchQuote}
                                disabled={actionLoading || swapping}
                            >
                                {actionLoading ? (
                                    <div className="flex items-center">
                                        <LoadingIcon.Oval
                                            strokeWidth={20}
                                            height="14px"
                                            stroke={ROOT_STYLE.THEME.SECONDARY}
                                        />
                                        Fetching Quote...
                                    </div>
                                ) : (
                                    swapping ? (
                                        <div className="flex items-center">
                                            <LoadingIcon.Oval
                                                strokeWidth={20}
                                                height="14px"
                                                stroke={ROOT_STYLE.THEME.SECONDARY}
                                            />
                                            Fetching Quote...
                                        </div>
                                    ) : (
                                        "Review"
                                    )
                                )}
                            </button>

                            <button
                                className="w-[49%] h-[10dvh] flex items-center justify-center shrink-0 rounded-md bg-[#0162d0] text-white px-3.5 py-2.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                                disabled={actionLoading || !quote || swapping}
                                onClick={executeSwapTransaction}
                            >
                                {actionLoading ? (
                                    <div className="flex items-center">
                                        <LoadingIcon.Oval
                                            strokeWidth={20}
                                            height="14px"
                                            stroke={ROOT_STYLE.THEME.SECONDARY}
                                        />
                                        Loading...
                                    </div>
                                ) : (
                                    "Confirm Swap"
                                )}
                            </button>
                        </div>

                        <button
                            className="w-full h-[10dvh] flex items-center justify-center shrink-0 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-100 hover:bg-gray-50"
                            disabled={actionLoading || swapping}
                            onClick={fetchAndExecuteAddLiquidityTranasction}
                        >
                            {actionLoading ? (
                                <div className="flex items-center">
                                    <LoadingIcon.Oval
                                        strokeWidth={20}
                                        height="14px"
                                        stroke={ROOT_STYLE.THEME.SECONDARY}
                                    />
                                    Loading...
                                </div>
                            ) : (
                                "Add Liquidity"
                            )}
                        </button>

                        {
                            userRole < ROLE.AGGREGATOR_SWAP.REMOVE_LOGO_ROLE ? <Banner /> : <></>
                        }
                    </div> :
                    <InvalidAccesskeyFrame />
            }
        </>
    );
};

export default AggregationCryptoExchangeWidget;