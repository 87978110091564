const ENDPOINT = {
  BASE_URL: `https://pure20.fanbase.io`,
  PAYMENT_BASE_URL: `http://localhost:8000`,
  AGGREGATOR_BASE_URL: `https://agg20.fanbase.io`,
  // AGGREGATOR_BASE_URL: `http://localhost:8000`,
  NFT_BASE_URL: `https://pure1155.fanbase.io`,
  AGGREGATOR_NFT_BASE_URL: `https://agg1155.fanbase.io`,
  // NFT_BASE_URL: `http://localhost:8000`,
  // AGGREGATOR_NFT_BASE_URL: 'http://localhost:8000',
  API_VERSION: {
    V1: "/v1",
    V2: "/v2",
  },
  COINGECKO_MAINNET_TOKEN_LIST: `https://tokens.coingecko.com/uniswap/all.json`,
  UNISWAP_TOKEN_LIST: `https://tokens.uniswap.org`,
  RAMP_TEST: `https://app.demo.ramp.network`,
  RAMP_PRODUCTION: `https://app.demo.ramp.network`
};

export default ENDPOINT;