import React, { useState, useEffect } from "react";
import { AiOutlineSwap } from "react-icons/ai";
import { useWidget } from "../context/WidgetContext";
import { ROLE, ROOT_STYLE, MESSAGE } from "../constant";
import TokenInput from "../components/TokenInput";
import TopSettingBar from "../components/TobSettingBar";
import Banner from "../components/Banner";
import InvalidAccesskeyFrame from "../../../pages/InvalidAccessKey";
import Swal from "sweetalert2";
import LoadingIcon from "react-loading-icons";

import { Buffer } from "buffer";
import { tokenItem, transaction } from "../type";

import { SwapAction, PaymentAction } from "../action";
import { ethers } from "ethers";
import { useAccountModal } from "@rainbow-me/rainbowkit";
import { useAccount, useNetwork } from "wagmi";

import { Util } from "../helper";

window.Buffer = window.Buffer || Buffer;

interface CryptoExchangeWidgetPropTypes {
    appId?: string;
    buyToken?: string;
    domain?: string;
}

const CryptoExchangeWidget: React.FC<CryptoExchangeWidgetPropTypes> = ({
    appId,
    buyToken,
    domain
}) => {
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { slippage } = useWidget();
    const { accountModalOpen } = useAccountModal()

    const [userRole, setUserRole] = useState(ROLE.PURE_SWAP.REMOVE_LOGO_ROLE)

    const [approveTransaction, setApproveTransaction] = useState<transaction[]>(
        []
    );
    const [swapTransaction, setSwapTransaction] = useState<transaction[]>([]);
    const [inputToken, setInputToken] = useState<tokenItem>();
    const [outputToken, setOutputToken] = useState<tokenItem>();
    const [inputAmount, setInputAmount] = useState<string>("");
    const [outputAmount, setOutputAmount] = useState<string>("");
    const [actionLoading, setActionLoading] = useState<boolean>(false);
    const [focusedOn, setFocusedOn] = useState<"input" | "output">("input");

    const onFocusOnInput = () => {
        setFocusedOn("input");
    };

    const onFocusOnOutput = () => {
        setFocusedOn("output");
    };

    const handleTokenInput = (token: tokenItem) => {
        setInputToken(token);
    };

    const handleTokenOutput = (token: tokenItem) => {
        setOutputToken(token);
    };

    const handleAmountInput = (amount: string) => {
        setInputAmount(amount);
        setOutputAmount("0")
    };

    const handleAmountOutput = (amount: string) => {
        setOutputAmount(amount);
    };

    const handleSwapDirection = () => {
        let temporaryToken: tokenItem | undefined;
        let temporaryAmount: string;

        temporaryToken = inputToken;
        temporaryAmount = inputAmount;

        setInputToken(outputToken);
        setOutputToken(temporaryToken);

        setInputAmount(outputAmount);
        setOutputAmount(temporaryAmount);
    };


    const clearSwap = () => {
        setInputToken(undefined)
        setOutputToken(undefined)
        setInputAmount("0")
        setOutputAmount("0")
    }

    const onFetchQuote = async () => {
        if (!address) {
            Swal.fire("Connection error", "Please connect your wallet", "error");
            return;
        }

        if (!inputToken || !outputToken) {
            Swal.fire("Invalid input", "Please select a token to send and one to receive", "error");
            return;
        }

        if (
            (focusedOn === "input" && (isNaN(Number(inputAmount)) || !inputAmount)) ||
            (focusedOn === "output" && (isNaN(Number(outputAmount)) || !outputAmount))
        ) {
            Swal.fire(
                "Invalid input",
                "Please input only numerical amount data",
                "error"
            );
            return;
        }

        if(Number(inputAmount) <= 0) {
            Swal.fire(
                "Invalid input",
                MESSAGE.ERROR.FORCE_INPUT_AMOUNT,
                "error"
            );
            return;
        }

        if (focusedOn === "input") {
            try {
                setActionLoading(true);

                const data = await SwapAction.fetchQuote(
                    chain?.id!,
                    inputToken?.address,
                    outputToken?.address,
                    ethers.parseUnits(inputAmount, inputToken.decimals).toString()
                );
                console.log(data)

                setOutputAmount(data)
            } catch (error) {
                Swal.fire("Action failed", "Failed to fetch swap data", "error");
                console.error(error);
            } finally {
                setActionLoading(false);
            }
        } else {
            try {
                setActionLoading(true);

                const data = await SwapAction.fetchReverseQuote(
                    chain?.id || 1,
                    inputToken?.address,
                    outputToken?.address,
                    ethers.parseUnits(outputAmount, outputToken.decimals).toString()
                );

                setInputAmount(Number(data).toFixed(3));

                setActionLoading(false);
                console.log(data);
            } catch (error) {
                setActionLoading(false);
                Swal.fire("Action failed", "Failed to fetch swap data", "error");
                console.log(error)
            }
        }
        
        try {
            const swapTransaction = await SwapAction.fetchSwapTransaction(
                chain?.id!, // chainId
                inputToken.address, // inputAddress
                outputToken.address, // outputAddress
                address, // fromAddress
                ethers.parseUnits(inputAmount, inputToken.decimals).toString(), // amount
                slippage, // slippage
            );

            console.log("swapTransaction", swapTransaction);

            setSwapTransaction(swapTransaction)
        } catch (error) {
            Swal.fire("Action failed", "Failed to fetch swap data", "error");
            console.error(error);
        }
    };

    const executeSwapTransaction = async () => {
        if (swapTransaction.length === 0) {
            Swal.fire(
                "Action failed",
                "There is no exchange swap data to execute",
                "error"
            );
            return;
        }

        try {
            setActionLoading(true);

            Swal.fire({
                title: "Please confirm this transaction in your wallet.",
                icon: "success"
            })

            const provider = new ethers.BrowserProvider(window?.ethereum!);
            const signer = provider.getSigner();
            for await (const transaction of swapTransaction) {
                try {
                    const sent = await (await signer).sendTransaction(transaction);
                    const confirmed = await sent.wait();
                    console.log(confirmed);
                } catch (error) {
                    Swal.fire(
                        "Action failed",
                        "The transaction was not successed",
                        "error"
                    );
                    setActionLoading(false);
                    console.log(error);
                    throw error;
                }
            }
            Swal.fire(
                "Successful Transaction!",
                `${inputAmount} ${inputToken?.symbol} exchanged to ${outputAmount} ${outputToken?.symbol}`,
                "success"
            );
            setActionLoading(false);

            clearSwap()
        } catch (error) {
            setActionLoading(false);
            console.log(error);
        }
    }

    const fetchAndExecuteAddLiquidityTranasction = async () => {
        try {
            const bigNumberInputAmount = ethers
                .parseUnits(inputAmount.toString(), inputToken?.decimals)
                .toString();
            const bigNumberOutputAmount = ethers
                .parseUnits(outputAmount.toString(), outputToken?.decimals)
                .toString();
            const transactions = await SwapAction.fetchAddLiquidityTransaction(
                chain?.id,
                inputToken?.address,
                outputToken?.address,
                address?.toString(),
                bigNumberInputAmount,
                bigNumberOutputAmount,
                appId
            );

            try {
                setActionLoading(true);

                const provider = new ethers.BrowserProvider(window?.ethereum!);
                const signer = provider.getSigner();
                for await (const transaction of transactions) {
                    try {
                        const sent = await (await signer).sendTransaction(transaction);
                        const confirmed = await sent.wait();
                        console.log(confirmed);
                    } catch (error) {
                        throw error;
                    }
                }
                Swal.fire(
                    "Successful Transaction!",
                    `${inputAmount} ${inputToken?.symbol} and${outputAmount} ${outputToken?.symbol} added liquidity`,
                    "success"
                );
            } catch (error) {
                Swal.fire(
                    "Action failed",
                    "The transaction execution was not successed",
                    "error"
                );
                console.error("fetchAndExecuteAddLiquidityTranasction() ==>", error);
            } finally {
                setActionLoading(false);
            }
        } catch (error) {
            Swal.fire(
                "Action failed",
                `Select a pair, review, then add liquidity to Fanbase Pools at the current rate.`,
                "error"
            );
            console.error("fetchAndExecuteAddLiquidityTranasction() ==>", error);
        }
    };

    // useEffect(() => {
    //     (async () => {
    //         if (chain?.id) {
    //             try {
    //                 if (!!appId) {
    //                     const role = await PaymentAction.verifyHashkey(chain.id, appId, domain || "")
    //                     setUserRole(role.data)
    //                 }
    //                 else {
    //                     setUserRole(ROLE.PURE_SWAP.RENDER_ROLE)
    //                 }
    //             }
    //             catch (error) {
    //                 setUserRole(ROLE.PURE_SWAP.RENDER_ROLE)
    //             }
    //         }
    //     })()
    // }, [chain?.id])

    useEffect(() => {
        if(accountModalOpen) {
            Swal.fire(MESSAGE.TITLE.CHANGE_ACCOUNT, MESSAGE.INFO.CHANGE_ACCOUNT, "info")
        }
    }, [accountModalOpen])

    /**
     * account change hook
     */
    useEffect(() => {
        clearSwap()
    }, [address, chain])

    /**
     * Detect network change...
     */
    useEffect(() => {
        setInputToken(undefined)
        setOutputToken(undefined)

        setInputAmount("")
        setOutputAmount("")
    }, [chain?.id])
    return (
        <div className="flex flex-col w-full h-[100dvh] bg-white rounded-xl text-[#263238] p-6">
            <TopSettingBar title="Pure Swap" />

            <TokenInput
                label="You send"
                token={inputToken}
                onTokenSelected={handleTokenInput}
                onAmountChange={handleAmountInput}
                amount={inputAmount}
                symbol={inputToken?.symbol}
                logo={inputToken?.logo}
                onFocus={onFocusOnInput}
                onQuoteSelect={() => { }}
            />

            <button
                onClick={handleSwapDirection}
                className="flex w-[15%] h-[8dvh] items-center justify-center text-align-center justify-items-center mx-auto rounded-md bg-white px-6 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
                <AiOutlineSwap className="flex" size={'70%'} />
            </button>

            <TokenInput
                disabled
                label="You receive"
                token={outputToken}
                onTokenSelected={handleTokenOutput}
                onAmountChange={handleAmountOutput}
                amount={outputAmount}
                symbol={outputToken?.symbol}
                logo={outputToken?.logo}
                onFocus={onFocusOnOutput}
                onQuoteSelect={() => { }}
                limited={ userRole >= ROLE.PURE_SWAP.UNLIMITED_TOKEN_INPUT_ROLE }
                fixedToken={buyToken}
                to
            />

            <div className="flex items-center justify-between mb-2 mt-4">
                <button
                    className="w-[49%] h-[10dvh] flex items-center justify-center shrink-0 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={onFetchQuote}
                    disabled={actionLoading}
                >
                    {actionLoading ? (
                        <div className="flex items-center">
                            <LoadingIcon.Oval
                                strokeWidth={20}
                                height="14px"
                                stroke={ROOT_STYLE.THEME.SECONDARY}
                            />
                            Fetching Quote...
                        </div>
                    ) : (
                        "Review"
                    )}
                </button>

                <button
                    className="w-[49%] h-[10dvh] flex items-center justify-center shrink-0 rounded-md bg-[#0162d0] text-white px-3.5 py-2.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                    disabled={actionLoading}
                    onClick={executeSwapTransaction}
                >
                    {actionLoading ? (
                        <div className="flex items-center">
                            <LoadingIcon.Oval
                                strokeWidth={20}
                                height="14px"
                                stroke={ROOT_STYLE.THEME.SECONDARY}
                            />
                            Loading...
                        </div>
                    ) : (
                        "Confirm Swap"
                    )}
                </button>
            </div>

            <button
                className="w-full h-[10dvh] flex items-center justify-center shrink-0 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-100 hover:bg-gray-50"
                disabled={actionLoading}
                onClick={fetchAndExecuteAddLiquidityTranasction}
            >
                {actionLoading ? (
                    <div className="flex items-center">
                        <LoadingIcon.Oval
                            strokeWidth={20}
                            height="14px"
                            stroke={ROOT_STYLE.THEME.SECONDARY}
                        />
                        Loading...
                    </div>
                ) : (
                    "Add Liquidity"
                )}
            </button>

            {
                userRole < ROLE.PURE_SWAP.REMOVE_LOGO_ROLE ?  <Banner /> : <></>
            }
        </div>
    );
};

export default CryptoExchangeWidget;
