const InvalidAccesskeyFrame = () => {
    return (
        <div className="bg-white h-[100dvh]">
            <div className="flex flex-col justify-center justify-item-center items-center w-full h-[100dvh] md:text-xl lg:text-5xl">
                <h1 className="flex text-red-500 font-black">⚠Invalid access key. Please manage your plan.</h1>
            </div>
        </div>
    );
};

export default InvalidAccesskeyFrame;