import React, { useState, useEffect } from "react";
import { AiOutlineDown, AiOutlineExclamationCircle } from "react-icons/ai";
import NftContent from "./NftContent";
import { MESSAGE } from "../constant";
import { useAccount, useNetwork } from "wagmi";
import { ContractHelper } from "../helper";

interface TokenInputProps {
	label?: string;
	inputLabel?: string;
	amount?: string;
	onTokenSelected: (address: string, id: number) => void;
	onAmountChange: (amount: string) => void;
	onFocus?: () => void;
	amountDisabled?: boolean;
}

const NftInput: React.FC<TokenInputProps> = ({
	label,
	inputLabel,
	amount,
	onTokenSelected,
	onAmountChange,
	onFocus,
	amountDisabled
}) => {
	const { chain } = useNetwork();
	const { address } = useAccount();

	const [modalOpened, setModalOpened] = useState(false);
	const [tokenAddress, setTokenAddress] = useState<string>("");
	const [tokenID, setTokenID] = useState<number>();
	const [tokenUri, setTokenUri] = useState<string>("");
	const [userBalance, setUserBalance] = useState<number>(0);

	const [stateMessage, setStateMessage] = useState<string>("");
	const [tokenDetected, setTokenDetected] = useState<boolean>(false);

	const handleTokenSearch = async (address: string) => {
		setTokenAddress(address)

		const isContractAddress = await ContractHelper.isContractAddress(chain?.id || 1, address)
		const isERC1155 = await ContractHelper.isERC1155(chain?.id || 1, address)

		if (isContractAddress && isERC1155) {
			setStateMessage('')
			setTokenDetected(true)
		}
		else {
			setStateMessage(MESSAGE.ERROR.NOT_ERC1155CONTRACT)
			setTokenDetected(false)
		}
	};

	const handleTokenIDInput = async (tokenId: string) => {

		setTokenID(parseInt(tokenId))

		try {
			const { balance, uri } = await ContractHelper.getERC1155Info(
				chain?.id || 1,
				tokenAddress,
				address || "",
				parseInt(tokenId)
			);
				
			setTokenUri(uri);
			setUserBalance(Number(balance));
		} catch (error) {
			console.error(error);
		}
	};

	const onTokenAmountChange = (amountString: string) => {
		onAmountChange(amountString);
	};

	const selectToken = () => {
		if (isNaN(Number(tokenID))) {
			return
		}
		onTokenSelected(tokenAddress, Number(tokenID));
		setModalOpened(false);
	};

	return (
		<div className="w-full">
			<div className="flex items-center my-3">
				<div className="flex flex-col justify-between w-full h-15 bg-[#f3f7f9] border border-black/10 mr-2 rounded-md outline-0">
					<div className="text-xs text-gray-500 pl-[10px] font-medium">
						{inputLabel}
					</div>
					<div className="flex flex-row justify-between">
						<input
							placeholder="0"
							value={amount}
							onChange={(e) => onTokenAmountChange(e.target.value)}
							type="number"
							className="flex w-full bg-[#f3f7f9] pl-[10px] rounded-md outline-0"
							onFocus={onFocus}
							disabled={amountDisabled}
						/>
					</div>
				</div>

				<button
					type="button"
					className="flex items-center justify-center shrink-0 rounded-md bg-white w-[30%] px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					onClick={() => setModalOpened(true)}
				>
					{label ? (
						<div className="flex items-center">
							<p>{label}</p>
						</div>
					) : (
						"Select Token"
					)}
					<AiOutlineDown className="ml-1.5" />
				</button>
			</div>

			{modalOpened ? (
				<div className="flex items-center justify-center absolute inset-0 z-10">
					<div
						className="flex items-center justify-center fixed inset-0 bg-black/50 z-10"
						onClick={() => {
							setModalOpened(false);
						}}
					/>
					<div className="bg-white w-10/12 lg:w-1/3 mx-auto p-4 rounded-xl z-20">
						<div className="border-b border-b-black/10 pb-2 mb-2">
							<h5 className="text-xl font-medium">Select Token</h5>

							<div className="flex items-center">
								<input
									placeholder="ERC-1155 token address"
									value={tokenAddress}
									onChange={(e) => handleTokenSearch(e.target.value)}
									type="text"
									className="mt-2 w-10/12 bg-white border border-black/10 mr-2 mb-2 px-3 py-1 rounded-md"
								/>
								<input
									placeholder="Token ID"
									value={tokenID}
									onChange={(e) => handleTokenIDInput(e.target.value)}
									type="number"
									className="mt-2 w-2/12 bg-white border border-black/10 mr-2 mb-2 px-3 py-1 rounded-md"
									disabled={!tokenDetected}
								/>
							</div>

							{stateMessage ? (
								<div className="flex items-center justify-center">
									<AiOutlineExclamationCircle
										color="#a83232"
										className="mr-1.5"
									/>

									<p className="text-sm text-[#a83232]">{stateMessage}</p>
								</div>
							) : null}
						</div>

						<NftContent uri={tokenUri} />

						<div className="mt-4">
							<p className="text-center">Your balance is {userBalance}</p>

							<button
								type="button"
								className="flex items-center justify-center shrink-0 mt-1 rounded-md bg-white w-full px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
								onClick={selectToken}
							>
								Select Token
							</button>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default NftInput;