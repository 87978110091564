import { axios, axios2 } from "../lib";

export default class SwapAction {
    static async fetchQuote(chainId: number, inputAddress: string, outputAddress: string, inputAmount: any) {
        try {
            const response = await axios.get(`/quote?chainId=${chainId}&inputAddress=${inputAddress}&outputAddress=${outputAddress}&inputAmount=${inputAmount}`)

            return response.data.data
        }
        catch (error: any) {
            console.error(error)
            throw error
        }
    }

    static async fetchReverseQuote(chainId: number, inputAddress: string, outputAddress: string, outputAmount: any) {
        try {
            const response = await axios.get(`/reverseQuote?chainId=${chainId}&inputAddress=${inputAddress}&outputAddress=${outputAddress}&outputAmount=${outputAmount}`)
            return response.data.data
        }
        catch (error: any) {
            console.error(error)
            throw error
        }
    }

    static async fetchSwapTransaction(chainId: number, inputAddress: string, outputAddress: string, fromAddress: string, inputAmount: any, slippage: number,) {
        try {
            const data = { chainId, inputAddress, outputAddress, fromAddress, inputAmount, slippage }

            const response = await axios.post(`/swap`, data)

            return response.data.data
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchAddLiquidityTransaction(chainId: number | undefined, tokenA: string | undefined, tokenB: string | undefined, fromAddress: string | undefined, amountA: any, amountB: any, appId: string | undefined) {
        try {
            const data = { chainId, tokenA, tokenB, fromAddress, amountA, amountB }
            if (appId) {
                const response = await axios2.post(`/addLiquidity`, data, { headers: { 'Authorization': appId } })

                return response.data.data
            }
            else {
                const response = await axios.post(`/addLiquidity`, data)

                return response.data.data
            }
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchPairList(chainId: number, toeknAddress: string, currencyAddress: string) {
        try {
            const response = await axios.get(`/getPairList?chainId=${chainId}&tokenAddress=${toeknAddress}&currencyAddress=${currencyAddress}`)

            return response.data.data
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchNftBuyData(chainId: number, tokenAddress: string, currencyAddress: string, tokenId: number, buyAmount: any, appId: string | undefined) {
        try {
            const data = { chainId, tokenAddress, currencyAddress, tokenId, buyAmount }

            if (appId) {
                const response = await axios2.post(`/dataToBuyToken`, data, { headers: { 'Authorization': appId } })

                return response.data.data
            }
            else {
                const response = await axios.post(`/dataToBuyToken`, data)

                return response.data.data
            }
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchNftSellData(chainId: number, tokenAddress: string, currencyAddress: string, tokenId: number, sellAmount: any, appId: string | undefined) {
        try {
            const data = { chainId, tokenAddress, currencyAddress, tokenId, sellAmount }

            if (appId) {
                const response = await axios2.post(`/dataToSellToken`, data, { headers: { 'Authorization': appId } })

                return response.data.data
            }
            else {
                const response = await axios.post(`/dataToSellToken`, data)

                return response.data.data
            }
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchTokenToCurrencyPrice(chainId: number, tokenId: number, tokenAmount: any, exchangeAddress: string) {
        try {
            const params = { chainId, tokenId, tokenAmount, exchangeAddress }
            const response = await axios.get(`/getTokenToCurrencyPrice`, { params })

            return response.data.data
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchCurrencyToTokenPrice(chainId: number | undefined, tokenId: number | undefined, tokenAmount: BigInt, exchangeAddress: string | undefined) {

        const params = { chainId, tokenId, tokenAmount, exchangeAddress }

        try {
            const response = await axios.get(`/getCurrencyToTokenPrice`, { params })

            return response.data.data
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchBuyTransaction(chainId: number, currencyAddress: string, tokenId: number, buyAmount: any, currencyAmount: any, recipient: string, feeRecipient: string, feeAmount: number, exchangeAddress: string, appId: string | undefined) {
        try {
            const data = { chainId, currencyAddress, tokenId, buyAmount, currencyAmount, recipient, feeRecipient, feeAmount, exchangeAddress }

            if (appId) {
                const response = await axios2.post(`/buyToken`, data, { headers: { 'Authorization': appId } })

                return response.data.data
            }
            else {
                const response = await axios.post(`/buyToken`, data)

                return response.data.data
            }
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchSellTransaction(chainId: number, tokenAddress: string, recipient: string, tokenId: number, tokenAmount: any, exchangeAddress: string, appId: string | undefined) {
        try {
            const data = { chainId, tokenAddress, recipient, tokenId, tokenAmount, exchangeAddress }

            if (appId) {
                const response = await axios2.post(`/sellToken`, data, { headers: { 'Authorization': appId } })

                return response.data.data
            }
            else {
                const response = await axios.post(`/sellToken`, data)

                return response.data.data
            }
        }
        catch (error: any) {
            throw error
        }
    }
}