import { Network } from 'alchemy-sdk'

const AlchemyChain: { [key: number]: Network } = {
    1: Network.ETH_MAINNET,
    5: Network.ETH_GOERLI,
    10: Network.OPT_MAINNET,
    137: Network.MATIC_MAINNET,
    420: Network.OPT_GOERLI,
    42161: Network.ARB_MAINNET,
    80001: Network.MATIC_MUMBAI,
    421613: Network.ARB_GOERLI,

}

export default AlchemyChain