import { axios2 } from "../lib";

export default class AggregatorSwapAction {
    static async fetchQuote(chainId: number, inputAddress: string, outputAddress: string, inputAmount: any) {
        try {
            const response = await axios2.get(`/quotes?chainId=${chainId}&inputAddress=${inputAddress}&outputAddress=${outputAddress}&inputAmount=${inputAmount}`)
            
            return response.data
        }
        catch (error: any) {
            console.error(error)
            throw error
        }
    }

    static async fetchReverseQuote(chainId: number | undefined, inputAddress: string | undefined, outputAddress: string | undefined, outputAmount: BigInt) {
        try {
            const response = await axios2.get(`/reverseQuote?chainId=${chainId}&inputAddress=${inputAddress}&outputAddress=${outputAddress}&outputAmount=${outputAmount}`)
            return response.data.data
        }
        catch (error: any) {
            console.error(error)
            throw error
        }
    }

    static async fetchSwapTransaction(chainId: number | undefined, inputAddress: string, outputAddress: string, fromAddress: string, inputAmount: any, slippage: number, dexName: string) {
        try {
            const data = { chainId, inputAddress, outputAddress, fromAddress, inputAmount, slippage, dexName }

            const response = await axios2.post(`/swap`, data)

            return response.data.data
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchAddLiquidityTransaction(chainId: number | undefined, tokenA: string | undefined, tokenB: string | undefined, fromAddress: string | undefined, amountA: any, amountB: any, appId: string | undefined) {
        try {
            const data = { chainId, tokenA, tokenB, fromAddress, amountA, amountB }
            const response = await axios2.post(`/addLiquidity`, data)

            return response.data.data
        }
        catch (error: any) {
            throw error
        }
    }
}