import React, { createContext, useContext, useState } from "react";
import { MESSAGE, SETTING } from "../constant";

interface WidgetContextProviderProps {
    children: React.ReactNode
}

type contextValueType = {
    slippage: number
    setSlippage: (slippage: number) => void
}

const WidgetContext = createContext<contextValueType>({
    slippage: SETTING.SWAP_SETTING.DEFAULT_SLIPPAGE,
    setSlippage: () => {}
})

const WidgetProvider = (props: WidgetContextProviderProps) => {

    const [slippage, setSlippage] = useState<number>(SETTING.SWAP_SETTING.DEFAULT_SLIPPAGE)

    const contextValue: contextValueType = { 
        slippage,
        setSlippage
    }
    return (
        <WidgetContext.Provider value={contextValue}>
            { props.children }
        </WidgetContext.Provider>
    )
}

export const useWidget = () => {
    const context = useContext(WidgetContext)

    if(context === undefined) {
        throw new Error(MESSAGE.ERROR.CONTEXT)
    }

    return context
}


export default WidgetProvider