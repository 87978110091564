import React, { useState, useEffect } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { ContractHelper } from "../helper";
import { NATIVE_COIN } from "../constant";
import { useNetwork, useAccount } from "wagmi";
import TokenListItem from "./TokenListItem";
import { uniswapTokenItem, tokenItem } from "../type";
import { getTokenDetail, getTokenBalance } from "../actions/Swap";
import ImageLoader from "./ImageLoader";
import { IMAGE } from "../constant";
import type { quote } from "../type"
import { ethers } from "ethers";
import { MoralisAction } from "../action";

interface TokenInputProps {
	token?: tokenItem;
	symbol?: string;
	logo?: string;
	amount?: string;
	amountDisabled?: boolean;
	quotes?: quote[];
	limited?: boolean;
	fixedToken?: string;
	label?: string;
	to?: boolean;
	disabled?: boolean;
	onTokenSelected: (token: tokenItem) => void;
	onAmountChange: (amount: string) => void;
	onFocus?: () => void;
	onQuoteSelect: (dexName: string) => void
}

const TokenInput: React.FC<TokenInputProps> = ({
	token,
	symbol,
	logo,
	amount,
	quotes,
	limited,
	fixedToken,
	label,
	to,
	disabled,
	onTokenSelected,
	onAmountChange,
	onFocus,
	onQuoteSelect
}) => {
	const { chain, chains } = useNetwork();
	const { address } = useAccount()

	const [modalOpened, setModalOpened] = useState(false);
	const [tokenList, setTokenList] = useState<uniswapTokenItem[]>([]);
	const [permanentTokenList, setPermanentTokenList] = useState<
		uniswapTokenItem[]
	>([]);

	const [tokenPrice, setTokenPrice] = useState<number>(0)
	const [tokenPriceLabel, setTokenPriceLabel] = useState<string>("-")

	const [isTokenSelected, setIsTokenSelected] = useState<boolean>(false)
	const [searchText, setSearchText] = useState<string>();


	const handleTokenSearch = async (address: string) => {
		setSearchText(address);

		if (address === "") {
			setTokenList(permanentTokenList);
			return;
		}

		try {
			const token: uniswapTokenItem = await getTokenDetail(
				address,
				chain?.id || 1
			);
			setTokenList([token]);
		}
		catch (error) {
			console.log(error)
			const filteredList = permanentTokenList.filter(
				(item) =>
					item.symbol.toLowerCase().includes(address.toLowerCase()) ||
					item.name.toLowerCase().includes(address.toLowerCase())
			);
			setTokenList(filteredList);
		}
	};

	const onTokenItemSelected = async (token: tokenItem) => {
		if (chain?.id && address) {
			try {
				const balanceBn = await getTokenBalance(chain?.id, token.address, address)
				const balanceSn = ethers.formatUnits(balanceBn, token.decimals)
				const balance = parseFloat(balanceSn).toFixed(4)



				onTokenSelected({ ...token, balance })
				setIsTokenSelected(true)
				setModalOpened(false)
			}
			catch (error) {
				console.error(error)

				onTokenSelected({ ...token, balance: 0 })
				setIsTokenSelected(true)
				setModalOpened(false)
			}
		}
	};

	const onTokenAmountChange = (amountString: string) => {
		onAmountChange(amountString);
	};

	const handleAmountMax = () => {
		onAmountChange(token?.balance?.toString() || "0")
	}

	/**
	 * fetch initial token item
	 */
	useEffect(() => {
		(async () => {
			try {
				if (!!limited) {
					if (!!fixedToken && chain?.id) {
						const token: uniswapTokenItem = await getTokenDetail(
							fixedToken,
							chain?.id
						);

						console.log({ token })
						setTokenList([token]);
					}
					else {
						setTokenList([]);
					}
				}
				else {
					const list = await ContractHelper.getTokenList(chain?.id || 1);
					setPermanentTokenList(list);
					setTokenList(list);
				}
			}
			catch (error) {
				console.error(error)
				setPermanentTokenList([]);
				setTokenList([]);
			}
		})();
	}, [chain?.id]);

	/**
	 * get token USD price when amount change
	 */
	useEffect(() => {
		(async () => {
			if(!!amount && chain && token) {
				try {
					if(token?.address === NATIVE_COIN[chain?.id].address) {
						const data = await MoralisAction.getTokenPrice(chain.id, NATIVE_COIN[chain.id].wrapped ||  NATIVE_COIN[chain?.id].address)
						const price = data.usdPrice * parseFloat(amount)
						setTokenPrice(price)
						setTokenPriceLabel(price.toFixed(4))
					}
					else {
						const data = await MoralisAction.getTokenPrice(chain?.id, token?.address)
						const price = data.usdPrice * parseFloat(amount)
						setTokenPrice(price)
						setTokenPriceLabel(price.toFixed(4))
					}
				}
				catch(error) {
					setTokenPrice(0)
					setTokenPriceLabel("-")

					console.error("useEffect() ==> get token USD price ==>", error)
				}
			}
			else {
				setTokenPrice(0)
				setTokenPriceLabel("-")
			}
		})()
	}, [amount])

	return (
		<div className="w-full mb-[10px] mt-[10px]">
			<div>
				<div className="flex items-center w-[100%]">
					<div className="flex flex-col w-[70%] justify-between h-15 bg-[#f3f7f9] border border-black/10 mr-2 rounded-md outline-0">
						<div className="text-xs text-gray-500 pl-[10px] font-medium">
							{`${label} ${tokenPriceLabel}USD`}
						</div>
						<div className="flex flex-row justify-between">
							<input
								placeholder="0"
								value={amount}
								onChange={(e) => onTokenAmountChange(e.target.value)}
								type="number"
								className="flex w-full bg-[#f3f7f9] pl-[10px] rounded-md outline-0"
								onFocus={onFocus}
								disabled={!isTokenSelected || disabled}
							/>
						</div>
					</div>
					{
						quotes?.length ?
							<select color="default" className="w-[30%] h-10 bg-[#f3f7f9] border border-black/10 mr-2 px-3 py-1 rounded-md outline-0" onChange={(e) => onQuoteSelect(e.target.value)} >
								<option disabled defaultChecked>DEXs</option>
								{
									quotes.map(quote => <option key={quote.dexName} value={quote.dexName}>{quote.dexName}</option>)
								}
							</select> :
							<></>
					}
					<div className="flex w-[30%] flex-row item-center justify-center">
						<button
							type="button"
							className="flex w-[100%] item-center justify-center shrink-0 rounded-md bg-white w-36 h-[43px] px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
							onClick={() => setModalOpened(true)}
							style={{ alignItems: 'flex-end', paddingTop: '20px' }}
						>
							{symbol ? (
								<div className="flex items-center">
									<ImageLoader
										src={logo}
										className="mr-2 w-6 h-6 rounded-full"
										alt={symbol}
										altSrc={IMAGE.GREEN_CIRGLE}
									/>
									<p>{symbol}</p>
								</div>
							) : (
								"Select Token"
							)}
							<AiOutlineDown className="ml-1.5 mb-[2]" />
						</button>
					</div>
				</div>
				{
					!!token ?
						<div className="flex text-sm justify-end pr-[5px] gap-[5px]">
							<span className="flex">
								Balance: {token?.balance || 0}
							</span>
							{
								!to ?
									<div className="flex item-center align-text-bottom">
										<a href="#" onClick={handleAmountMax} className="flex font-medium text-sm text-blue-600 dark:text-blue-500 mr-[5px]">Max</a>
									</div> : <></>
							}
						</div> : <></>
				}
			</div>
			{modalOpened ? (
				<div className="flex items-center justify-center absolute w-full h-full inset-0 z-10">
					<div
						className="flex items-center justify-center fixed inset-0 bg-black/50 z-10 w-full h-full"
						onClick={() => setModalOpened(false)}
					/>

					<div className="bg-white w-10/12 lg:w-1/3 mx-auto p-4 rounded-xl z-20">
						<h5 className="text-xl font-medium">Select Token</h5>

						<input
							placeholder={ !!tokenList.length ? "Search name or paste address" : "Search by address"}
							value={searchText}
							onChange={(e) => handleTokenSearch(e.target.value)}
							type="text"
							className="mt-2 w-full bg-white border border-black/10 mr-2 mb-2 px-3 py-1 rounded-md"
							onFocus={onFocus}
							disabled={limited}
						/>

						<div className="max-h-[400px] overflow-auto">
							<TokenListItem
								address={NATIVE_COIN[1].address}
								decimals={chain?.nativeCurrency.decimals || 18}
								name={chain?.nativeCurrency.name || "ETH"}
								symbol={chain?.nativeCurrency.symbol || "ETH"}
								logo={NATIVE_COIN[chain?.id || 1]?.logo || IMAGE.NETWORK.ETHEREUM}
								onClick={onTokenItemSelected}
							/>

							{tokenList.map((token, index) => (
								<TokenListItem
									address={token.address}
									decimals={token.decimals}
									name={token.name}
									symbol={token.symbol}
									logo={token.logoURI}
									key={index}
									onClick={onTokenItemSelected}
								/>
							))}
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default TokenInput;
