import React from 'react'
import Carousel from 'react-multi-carousel'


import "react-multi-carousel/lib/styles.css"
import "../../style/customCarousel.css"

interface CustomCarouselProps {
    children: React.ReactNode
}
const CustomCarousel: React.FC<CustomCarouselProps> = ({ children }) => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <Carousel responsive={responsive} swipeable infinite centerMode >
            {children}
        </Carousel>
    )
}

export default CustomCarousel