import React, { useEffect, useState } from "react";
import { useWidget } from "../context/WidgetContext";
import TokenInput from "../components/TokenInput";
import NftInput from "../components/NftInput";
import TopSettingBar from "../components/TobSettingBar";
import Banner from "../components/Banner";
import NftExplorer from "../components/NftExplorer";
import InvalidAccesskeyFrame from "../../../pages/InvalidAccessKey";
import Swal from "sweetalert2";
import LoadingIcon from "react-loading-icons";
import { AiOutlineSwap } from "react-icons/ai";
import { ROOT_STYLE, CHAIN_TO_WFNB, ROLE, MESSAGE } from "../constant";

import { Buffer } from "buffer";
import { tokenItem, transaction, swapdata } from "../type";

import { AggregatorNftSwapAction, PaymentAction } from "../action";
import { getTokenDetail } from "../actions/Swap";
import { ethers } from "ethers";
import { useAccount, useNetwork } from "wagmi";
import { useAccountModal } from "@rainbow-me/rainbowkit";
import { twMerge } from "tailwind-merge";
import { Util } from "../helper";

window.Buffer = window.Buffer || Buffer;

interface NftExchangeWidgetPropTypes {
	appId?: string;
	buyToken?: string;
	domain?: string;
	coverView?: "carousel" | "list"
}

const AggregationNftExchangeWidget: React.FC<NftExchangeWidgetPropTypes> = ({ appId, domain, coverView }) => {
	const { chain } = useNetwork();
	const { address } = useAccount();
	const { slippage, setSlippage } = useWidget()
	const { accountModalOpen } = useAccountModal()

	const [userRole, setUserRole] = useState(ROLE.AGGREGATOR_NFT_SWAP.LOGO_REMOVE_ROLE)

	const [swapTransaction, setSwapTransaction] = useState<transaction[]>([]);
	const [swapData, setSwapData] = useState<swapdata>();
	const [currency, setCurrency] = useState<tokenItem>();
	const [tokenAddress, setTokenAddress] = useState<string>("");
	const [tokenId, setTokenId] = useState<number | string>();

	const [initialCurrencyAmount, setInitialCurrencyAmount] = useState(0)
	const [currencyAmount, setCurrencyAmount] = useState<string>("");
	const [tokenAmount, setTokenAmount] = useState<string>("");

	const [isPairSelected, setIsPairSelected] = useState<boolean>(false)

	const [actionLoading, setActionLoading] = useState<boolean>(false);
	const [focusedOn, setFocusedOn] = useState<"buy" | "sell">("buy");
	const [explorerOpened, setExplorerOpened] = useState<boolean>(false);
	const [getPairListLoading, setGetPairListLoading] = useState<boolean>(false);

	const [pairList, setPairList] = useState<string[] | []>([]);
	const [selectedPair, setSelectedPair] = useState<string>();

	const onSelectCurrency = (token: tokenItem) => setCurrency(token);

	const onSelectToken = (address: string, id: number) => {
		setTokenAddress(address);
		setTokenId(id);
	};

	const handleGalleryItemSelect = (address: string, id: string) => {
		setTokenAddress(address);
		setTokenId(id)

		setExplorerOpened(false);

		console.log(address)
	}

	const handleSwapDirection = () => setFocusedOn(focusedOn === 'buy' ? 'sell' : 'buy')

	const handleCurrencyAmount = (amount: string) => setCurrencyAmount(amount);
	const handleTokenAmount = (amount: string) => setTokenAmount(amount);

	const onExplorerOpen = () => setExplorerOpened(true);
	const onExplorerClose = () => setExplorerOpened(false);

	const clearSwap = () => {
		setCurrencyAmount("0")
		setCurrency(undefined)
		setTokenAmount("0")
	}

	const fetchPairList = async () => {
		if (!currency || !tokenAddress) {
			Swal.fire(
				"Input error",
				"Please input currency and NFT token item",
				"error"
			);
			return;
		}

		try {
			setGetPairListLoading(true);

			const exchanges = await AggregatorNftSwapAction.fetchPairList(
				chain?.id || 1,
				tokenAddress,
				currency.address
			);

			setPairList(exchanges);

			setGetPairListLoading(false);
		} catch (error) {
			console.log(error);
			Swal.fire("Action failed", "The matched exchange pair not exist");
			setGetPairListLoading(false);
		}
	};

	const onPairSelected = (pair: any) => {
		setIsPairSelected(true)
		setSelectedPair(pair)
	}

	const fetchData = async () => {

		if (!isPairSelected) {
			Swal.fire(
				"Input error",
				"Please select a quote from the list provided",
				"error"
			);
			return;
		}

		if (focusedOn === "buy") {
			try {
				setActionLoading(true)

				const data = await AggregatorNftSwapAction.fetchNftBuyData(chain?.id || 1, tokenAddress, String(currency?.address), Number(tokenId), tokenAmount, appId)
				setSwapData(data)

				const price = await AggregatorNftSwapAction.fetchTokenToCurrencyPrice(chain?.id || 1, Number(tokenId), tokenAmount, data.exchange)
				const safeNumberPrice = ethers.formatUnits(price[0]['hex'], currency?.decimals)
				
				setInitialCurrencyAmount(Number(safeNumberPrice))
				setCurrencyAmount(Util.computeReverseSlippage(safeNumberPrice, slippage).toString())

				setActionLoading(false)
			}
			catch (error) {
				console.log(error)
				Swal.fire('Action failed', 'Current liquidity is insufficient')
				setActionLoading(false)
			}
		} else {
			try {
				setActionLoading(true)

				const data = await AggregatorNftSwapAction.fetchNftSellData(chain?.id || 1, tokenAddress, String(currency?.address), Number(tokenId), tokenAmount, appId)
				setSwapData(data)

				const price = await AggregatorNftSwapAction.fetchTokenToCurrencyPrice(chain?.id || 1, Number(tokenId), tokenAmount, data.exchange)
				const safeNumberPrice = ethers.formatUnits(price[0]['hex'], currency?.decimals)
				
				setInitialCurrencyAmount(Number(safeNumberPrice))
				setCurrencyAmount(Util.computeSlippage(safeNumberPrice, slippage).toString())

				setActionLoading(false)
			}
			catch (error: any) {
				console.log(error)
				Swal.fire('Action failed', 'The matched exchange pair seems not exist')
				setActionLoading(true)
			}
		}
	};

	const handleConfirmSwap = async () => {
		if(slippage >= 30) {
			await confirmSwap()
		}
		else {
			Swal.fire({
				title: "Do you want to swap with low slippage?",
				showDenyButton: true,
				confirmButtonText: "Confirm",
			}).then(async (result) => {
				if (result.isConfirmed) {
				  await confirmSwap()
				} else if (result.isDenied) {
				  return
				}
			});
		}
	}

	const confirmSwap = async () => {
		if (focusedOn === "buy") {
			try {
				setActionLoading(true);

				Swal.fire({
					title: "Please confirm this transaction in your wallet.",
					icon: "success"
				})

				const transactions = await AggregatorNftSwapAction.fetchBuyTransaction(
					chain?.id || 1,
					String(currency?.address),
					Number(tokenId),
					tokenAmount,
					ethers.parseUnits(currencyAmount, currency?.decimals).toString(),
					String(address),
					String(address),
					0,
					String(swapData?.exchange),
					appId
				);
				console.log(transactions);

				const provider = new ethers.BrowserProvider(window?.ethereum);
				const signer = provider.getSigner();
				for await (const transaction of transactions) {
					try {
						const sent = await (await signer).sendTransaction(transaction);
						const confirmed = await sent.wait();
						console.log(confirmed);
					} catch (error) {
						Swal.fire(
							"Action failed",
							"The transaction was not successed",
							"error"
						);
						setActionLoading(false);
						console.log(error);
						throw error;
					}
				}

				Swal.fire(
					"Success",
					`You bought ${tokenAmount} of NFT with ${currencyAmount} of ${currency?.symbol}`,
					"success"
				);
				setActionLoading(false);

				clearSwap()
			} catch (error) {
				Swal.fire(
					"Action failed",
					`Failed to execute swap transaction`,
					"error"
				);
				setActionLoading(false);
				console.error(error);
			}
		} else {
			try {
				const transactions = await AggregatorNftSwapAction.fetchSellTransaction(
					chain?.id || 1,
					tokenAddress,
					String(address),
					Number(tokenId),
					tokenAmount,
					String(swapData?.exchange),
					appId
				);
				console.log(transactions);

				const provider = new ethers.BrowserProvider(window?.ethereum!);
				const signer = provider.getSigner();
				for await (const transaction of transactions) {
					try {
						const sent = await (await signer).sendTransaction(transaction);
						const confirmed = await sent.wait();
						console.log(confirmed);
					} catch (error) {
						Swal.fire(
							"Action failed",
							"The transaction was not successed",
							"error"
						);
						setActionLoading(false);
						console.log(error);
						throw error;
					}
				}

				Swal.fire(
					"Success",
					`You sold ${tokenAmount} of NFT for ${currencyAmount} of ${currency?.symbol}`,
					"success"
				);
				setActionLoading(false);

				clearSwap()
			} catch (error) {
				Swal.fire(
					"Action failed",
					`Failed to execute swap transaction`,
					"error"
				);
				setActionLoading(false);
				console.error(error);
			}
		}
	};

	// useEffect(() => {
	// 	(async () => {
	// 		if (chain?.id) {
	// 			try {
	// 				if (!!appId) {
	// 					const role = await PaymentAction.verifyHashkey(chain.id, appId, domain || "")
	// 					console.log(role.data)
	// 					setUserRole(role.data)
	// 				}
	// 				else {
	// 					setUserRole(ROLE.AGGREGATOR_NFT_SWAP.BLOCK_ROLE)
	// 				}
	// 			}
	// 			catch (error) {
	// 				setUserRole(ROLE.AGGREGATOR_NFT_SWAP.BLOCK_ROLE)
	// 			}
	// 		}
	// 	})()
	// }, [chain?.id])

	useEffect(() => {
		(async () => {
			try {
				const token = await getTokenDetail(CHAIN_TO_WFNB[chain?.id || 1], chain?.id || 1)
				setCurrency({ address: token.address, symbol: token.symbol, decimals: token.decimals, name: token.name, logo: token.logoURI });
			}
			catch (error) {
				console.error(error)
			}
		})()
	}, [chain?.id])

	/**
	 * slippage control
	 */
	useEffect(() => {
		setSlippage(30)
	}, [])

	useEffect(() => {
		if(focusedOn === "buy") {
			setCurrencyAmount(Util.computeReverseSlippage(initialCurrencyAmount, slippage).toString())
		}
		if(focusedOn === "sell") {
			setCurrencyAmount(Util.computeSlippage(initialCurrencyAmount, slippage).toString())
		}
	}, [slippage])

	/**
	 * account modal hook
	 */
	useEffect(() => {
        if(accountModalOpen) {
            Swal.fire(MESSAGE.TITLE.CHANGE_ACCOUNT, MESSAGE.INFO.CHANGE_ACCOUNT, "info")
        }
    }, [accountModalOpen])


	useEffect(() => {
		if(!!coverView) {
			setExplorerOpened(true)
		}
	}, [coverView])

	/**
     * account change hook
     */
    useEffect(() => {
        clearSwap()
    }, [address, chain])

	/**
	 * Detect network change...
	 */
	useEffect(() => {
		setCurrency(undefined)
		setTokenAddress("")

		setCurrencyAmount("")
		setTokenAmount("")
	}, [chain?.id])
	return (
		<>
			{
				userRole >= ROLE.AGGREGATOR_NFT_SWAP.RENDER_ROLE ?
					<div className="flex flex-col w-full h-[100dvh] bg-white rounded-xl text-[#263238] p-6">
						<TopSettingBar title="Aggregator NFT Swap" />

						{
							focusedOn === 'buy' ?
								<TokenInput disabled label="You send" token={currency} onQuoteSelect={() => { }} onTokenSelected={onSelectCurrency} onAmountChange={handleCurrencyAmount} amount={currencyAmount} symbol={currency?.symbol} logo={currency?.logo} amountDisabled={true} /> :
								<NftInput inputLabel="You send" onTokenSelected={onSelectToken} onAmountChange={handleTokenAmount} amount={tokenAmount} label={tokenAddress ? Util.truncateEthereumAddress(tokenAddress, 5) : `1155 Token`} amountDisabled={!isPairSelected} />
						}

						<button
							onClick={handleSwapDirection}
							className="flex w-[15%] h-[8dvh] items-center justify-center text-align-center justify-items-center mx-auto rounded-md bg-white px-6 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						>
							<AiOutlineSwap className="flex" size={'70%'} />
						</button>

						{
							focusedOn === 'sell' ?
								<TokenInput disabled label="You receive" to token={currency} onQuoteSelect={() => { }} onTokenSelected={onSelectCurrency} onAmountChange={handleCurrencyAmount} amount={currencyAmount} symbol={currency?.symbol} logo={currency?.logo} amountDisabled={true} /> :
								<NftInput inputLabel="You receive" onTokenSelected={onSelectToken} onAmountChange={handleTokenAmount} amount={tokenAmount} label={tokenAddress ? Util.truncateEthereumAddress(tokenAddress, 5) : `1155 Token`} amountDisabled={!isPairSelected} />
						}

						<button
							onClick={fetchPairList}
							className="w-full h-[12dvh] flex items-center justify-center mx-auto rounded-md bg-white px-6 py-2.5 mt-4 mb-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						>
							{getPairListLoading ? (
								<div className="flex items-center">
									<LoadingIcon.Oval
										strokeWidth={20}
										height="14px"
										stroke={ROOT_STYLE.THEME.SECONDARY}
									/>
									Fetching Pairs...
								</div>
							) : (
								"Get Pair List"
							)}
						</button>

						{pairList?.length > 0 ? (
							<div className="mb-4">
								<p className="font-semibold mb-1">Select Pair</p>

								{pairList?.map((pair, index) => (
									<button
										onClick={() => onPairSelected(pair)}
										className={twMerge(
											"w-full flex items-center justify-between mx-auto rounded-md bg-white px-6 py-2.5 mb-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-[#0162d0]/10",
											pair.toLowerCase() ==
												selectedPair?.toLowerCase()
												? "bg-[#0162d0]/5"
												: null
										)}
										key={index}
									>
										<div className="flex items-center">
											<div className="flex items-center mr-2">
												<img
													src={currency?.logo}
													className="mr-2 w-5 h-5 rounded-full"
													alt={currency?.symbol}
												/>
												<p className="font-semibold">{currency?.symbol}</p>
											</div>

											<p className="font-semibold text-sm">
												{pair}
											</p>
										</div>
									</button>
								))}
							</div>
						) : null}

						<div className="flex items-center justify-between my-2">
							<button
								className={twMerge(
									"w-[49%] h-[10dvh] flex items-center justify-center shrink-0 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
									actionLoading || !selectedPair ? "opacity-50" : "opacity-100"
								)}
								disabled={actionLoading || !selectedPair}
								onClick={fetchData}
							>
								{actionLoading ? (
									<div className="flex items-center">
										<LoadingIcon.Oval
											strokeWidth={20}
											height="14px"
											stroke={ROOT_STYLE.THEME.SECONDARY}
										/>
										Fetching Quote...
									</div>
								) : (
									"Review Swap"
								)}
							</button>

							<button
								className="w-[49%] h-[10dvh] flex items-center justify-center shrink-0 rounded-md bg-[#0162d0] text-white px-3.5 py-2.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
								disabled={actionLoading}
								onClick={handleConfirmSwap}
							>
								{actionLoading ? (
									<div className="flex items-center">
										<LoadingIcon.Oval
											strokeWidth={20}
											height="14px"
											stroke={ROOT_STYLE.THEME.SECONDARY}
										/>
										Loading...
									</div>
								) : (
									"Confirm Swap"
								)}
							</button>
						</div>

						<button
							onClick={onExplorerOpen}
							className="w-full h-[10dvh] flex items-center justify-center mx-auto rounded-md bg-white px-6 py-2.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-100 hover:bg-gray-50"
						>
							NFT Explorer
						</button>

						{
							userRole < ROLE.AGGREGATOR_NFT_SWAP.LOGO_REMOVE_ROLE ? <Banner /> : <></>
						}
						<NftExplorer
							chainId={chain?.id || 1}
							open={explorerOpened && Boolean(address)}
							// account={"0x6F6a66f29B005b9db247c0b147F7D518477c90a8"}
							account={String(address)}
							onClose={onExplorerClose}
							onTokenImport={handleGalleryItemSelect}
							listView={coverView === "list"}
						/>
					</div> :
					<InvalidAccesskeyFrame />
			}
		</>
	);
};

export default AggregationNftExchangeWidget;