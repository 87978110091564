interface ICHAIN_TO_WFNB {
    [key: number]: string;
}

const CHAIN_TO_WFNB: ICHAIN_TO_WFNB = {
    1: '0x7016c6f4dba9a9a48a47a2fe73a1b9271432d1d5',
    56: '0x5370653C8A065cA4393Ffd3F892BD49178D85ced',
    137: '0x1d47536d368010A98343fF98Aa22BCB4B9d1a5BD',
    43114: '0x1d47536d368010A98343fF98Aa22BCB4B9d1a5BD',
    10: '0xfCDCBA599b2261f878CC9c244b2814FaE47F9b5B',
    5: '0xC883D4f7dAD93d4B7325Ed2B83eD56FD95E73c42',
    80001: '0x9Ee7B992ee40D66Cea8DE9904fdeF690a5908b67',
    43113: '0x4377E4bEB941b84aE687F71428b3Cd051Ac1BD58',
    420: '0x4377E4bEB941b84aE687F71428b3Cd051Ac1BD58',
    100: '0x10f8c3F4C1c6f85f0c8Be1B3Fec0429E44DBac2B',
    250: '0xBa3580c0F0d5c1dC243cC88ECBf84338c3054623',
    42220: '0x10f8c3F4C1c6f85f0c8Be1B3Fec0429E44DBac2B',
    44787: '0x4377E4bEB941b84aE687F71428b3Cd051Ac1BD58',
    122: '0x10f8c3F4C1c6f85f0c8Be1B3Fec0429E44DBac2B',
    1285: '0x1701098770E6A44c29A99b351d6A355520029BE6',
    42161: '0x10f8c3F4C1c6f85f0c8Be1B3Fec0429E44DBac2B',
    421613: '0x4377E4bEB941b84aE687F71428b3Cd051Ac1BD58',
    1313161554: '0x10f8c3F4C1c6f85f0c8Be1B3Fec0429E44DBac2B',
    1313161555: '0x4377E4bEB941b84aE687F71428b3Cd051Ac1BD58',
    288: '0x10f8c3f4c1c6f85f0c8be1b3fec0429e44dbac2b',
    1666600002: '0x10f8c3F4C1c6f85f0c8Be1B3Fec0429E44DBac2B',
    1287: '0x4377E4bEB941b84aE687F71428b3Cd051Ac1BD58',
    1024: '0xB05c4Cf6cf5989ca95273a2667515bD68aC8B029',
}

export default CHAIN_TO_WFNB