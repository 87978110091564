import React, { useEffect, useState } from 'react'
import { Container, Modal, Text, Button, Grid } from '@nextui-org/react'
import CustomCarousel from './common/CustomCarousel'
import NFTGalleryItem from './NFTGalleryItem'
import NFTListGallery from './NFTListGallery'
import AlchemyAction from '../action/AlchemyAction'
import { KEY_STORE } from '../constant'

import type { OwnedNft } from 'alchemy-sdk'

interface NftExplorerPropTypes {
    open: boolean
    chainId: number
    account: string
    listView?: boolean
    onClose: () => void
    onOpen?: () => void
    onTokenImport: (tokenAddress: string, tokenId: string) => void
}

const NftExplorer: React.FC<NftExplorerPropTypes> = ({ open, chainId, account, listView, onClose, onOpen, onTokenImport }) => {

    const [validNFTs, setValidNFTs] = useState<OwnedNft[]>([])
    const [isListView, setIsListView] = useState<boolean>(listView || false)

    useEffect(() => {
        (async () => {
            try {
                const alchemy = new AlchemyAction(KEY_STORE.ALCHEMY_KEY, chainId)

                const result = await alchemy.getWallet1155NFTs(account)

                setValidNFTs(result)

                console.log(result)
            }
            catch (error) {
                console.log(error)
            }
        })()
    }, [chainId])

    return (
        <Modal open={open} onClose={onClose} onOpen={onOpen} closeButton width='100%'>
            <Modal.Header>
                <Container>
                    <Grid.Container>
                        <Grid xs={12}>
                            <Button.Group color='primary' ghost size='sm'>
                                <Button style={isListView ? { width: '90px', borderColor: '#bbbbbb', backgroundColor: 'transparent', color: 'black'} : { width: '90px', borderColor: '#bbbbbb', backgroundColor: '#bbbbbb', color: 'black' }} onPress={() => setIsListView(false)}>Carousel</Button>
                                <Button style={isListView ? { width: '90px', borderColor: '#bbbbbb', backgroundColor: '#bbbbbb', color: 'black' } : { width: '90px', borderColor: '#bbbbbb', backgroundColor: 'transparent', color: 'black'}} onPress={() => setIsListView(true)}>List</Button>
                            </Button.Group>
                        </Grid>
                    </Grid.Container>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {isListView ?
                        <NFTListGallery onTokenImport={onTokenImport} nftList={validNFTs} />
                        :
                        <CustomCarousel>
                            {
                                validNFTs.map((nft, index) => <NFTGalleryItem onTokenImport={onTokenImport} key={index} nft={nft} />)
                            }
                        </CustomCarousel>
                    }

                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default NftExplorer