import React from 'react'
import { Collapse, Avatar, Text, Grid } from '@nextui-org/react'
import type { OwnedNft } from 'alchemy-sdk'
import NFTGalleryItem from './NFTGalleryItem'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { Util } from '../helper'
import { IMAGE } from '../constant'

import "../style/listGallery.css"

interface NFTListGalleryProps {
    nftList: OwnedNft[]
    onTokenImport: (tokenAddress: string, tokenId: string) => void
}

const NFTListGallery: React.FC<NFTListGalleryProps> = ({ nftList, onTokenImport }) => {
    return (
        <Grid.Container gap={2} justify='center'>
            <Grid justify='center' className='list-container'>
                <Collapse.Group shadow>
                {
                    nftList.map((nft, index) => (
                        <Collapse
                            key={index}
                            title={<Text h4 weight='bold'>{ nft.contract.name || nft.title }#{nft.tokenId}</Text>}
                            subtitle={ nft.contract.address }
                            style={{ overflow: "hidden" }}
                            contentLeft={
                                <Avatar
                                    size="xl"
                                    src={ Util.replaceSubstring(nft.media[0].thumbnail || nft.rawMetadata?.image || nft.rawMetadata?.external_url || IMAGE.IMAGE_ERROR) }
                                    color="default"
                                    bordered
                                    squared
                                />
                            }
                            arrowIcon={<MdKeyboardArrowRight />}
                        >
                            <NFTGalleryItem nft={nft} onTokenImport={onTokenImport}/>
                        </Collapse>
                    ))
                }
                </Collapse.Group>
            </Grid>
        </Grid.Container>
    )
}

export default NFTListGallery