const ROLE = {
    PURE_SWAP: {
        RENDER_ROLE: -1,
        REMOVE_LOGO_ROLE: 2,
        UNLIMITED_TOKEN_INPUT_ROLE: 0
    },
    AGGREGATOR_SWAP: {
        BLOCK_ROLE: -1,
        RENDER_ROLE: 0,
        REMOVE_LOGO_ROLE: 1,
        UNLIMITED_TOKEN_INPUT_ROLE: 2
    },
    AGGREGATOR_NFT_SWAP: {
        BLOCK_ROLE: -1,
        RENDER_ROLE: 1,
        LOGO_REMOVE_ROLE: 2
    }
}

export default ROLE