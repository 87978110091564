import axios from "axios"
import { KEY_STORE } from "../constant"

class MoralisAction {
    static getTokenPrice = async (chainId: number, address: string) => {

        const chain = `0x${chainId.toString(16)}`

        try {
            const response = await axios.get(
                `https://deep-index.moralis.io/api/v2.2/erc20/${address}/price?chain=${chain}&include=percent_change`,
                {
                    headers: {
                        'accept': 'application/json',
                        'X-API-Key': KEY_STORE.MORALIS_KEY
                    }
                }
            )
        
            return response.data
        }
        catch(error) {
            throw error
        }
    }

    static getEthPrice = async () => {

        try {
            const response = await axios.get(
                `https://deep-index.moralis.io/api/v2.2/erc20/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2/price?chain=eth&include=percent_change`,
                {
                    headers: {
                        'accept': 'application/json',
                        'X-API-Key': KEY_STORE.MORALIS_KEY
                    }
                }
            )
        
            return response.data
        }
        catch(error) {
            throw error
        }
    }
}

export default MoralisAction