import { Outlet } from "react-router-dom";
import { Container } from "@nextui-org/react";

const MainLayout = () => {
  return (
    <Container style={{ width: '100%', background: "transparent", height: "100dvh", padding: 0, margin: 0 }}>
        <Outlet />
    </Container>
  );
};

export default MainLayout;
