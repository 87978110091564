import axios from "axios";

export default class Util {
    static async parseMetadata(uri: string) {
        try {
            const metaData = await axios.get(uri);
            return metaData.data;
        } catch (error) {
            console.log(error);
            // throw error
        }
    }

    static numberToHex(number: number) {
        return number.toString(16);
    }

    static parseFileType(file: string) {
        const candidates = file.split(".");

        return candidates[candidates.length - 1]
    }

    static replaceSubstring(supString: string) {
        if (supString.indexOf('ipfs://') >= 0) {
            return supString.replace('ipfs://', 'https://ipfs.io/ipfs/')
        } else {
            return supString
        }
    }

    static prettyStringify(jsonObject: object): string {
        const _1 = JSON.stringify(jsonObject, null, 4);
        const _2 = _1.replace("\", \"", "\",\n\"");

        console.log(_2);

        return _2
    }

    static truncateEthereumAddress(address: string, chars: number = 4): string {
        const prefix = address.slice(0, 2); // Retrieve the first two characters
        const suffix = address.slice(-chars); // Retrieve the last `chars` characters
        return `${prefix}...${suffix}`;
    }

    static computeSlippage = (amountOut: any, slippage: any) => {
        const amountOutMin = Number(amountOut) - (Number(amountOut) * Number(slippage) / 100);
        return amountOutMin
    }

    static computeReverseSlippage = (amountInput: any, slippage: any) => {
        const amountOutMin = Number(amountInput) + (Number(amountInput) * Number(slippage) / 100);
        return amountOutMin
    }

    static toFixed(x: any) {
        if (Math.abs(x) < 1.0) {
            var e = parseInt(x.toString().split('e-')[1]);
            if (e) {
                x *= Math.pow(10, e - 1);
                x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
            }
        } else {
            var e = parseInt(x.toString().split('+')[1]);
            if (e > 20) {
                e -= 20;
                x /= Math.pow(10, e);
                x += (new Array(e + 1)).join('0');
            }
        }
        return x;
    }
}
