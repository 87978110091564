interface NetworkConstantProps {
  CHAINID_TO_NAME: {
    [key: number]: string;
  };
  CHAINID_TO_RPC: {
    [key: number]: string;
  };
  CHAINID_TO_SUBGRAPH: {
    [key: number]: string;
  };
  CHAINID_TO_GEYSER_ADDRESS: {
    [key: number]: string;
  };
}

const NETWORK_CONSTANT: NetworkConstantProps = {
  CHAINID_TO_NAME: {
    1: "Mainnet",
    5: "Goerli Test Network",
    10: "Optimism Main Network",
    56: "BNB Smart Chain",
    137: "Polygon Main Network",
  },
  CHAINID_TO_RPC: {
    1: 'https://mainnet.infura.io/v3/7a6077ec1ff04e70a21839f56d6a4ca1',
    5: 'https://goerli.infura.io/v3/7a6077ec1ff04e70a21839f56d6a4ca1',
    10: 'https://optimism-mainnet.infura.io/v3/7a6077ec1ff04e70a21839f56d6a4ca1',
    56: 'https://rpc.ankr.com/bsc',
    97: 'https://data-seed-prebsc-1-s3.binance.org:8545/',
    100: 'https://rpc.ankr.com/gnosis',
    122: 'https://fuse-rpc.gateway.pokt.network/',
    137: 'https://optimism-mainnet.infura.io/v3/7a6077ec1ff04e70a21839f56d6a4ca1',
    250: 'https://rpcapi.fantom.network/',
    288: 'https://mainnet.boba.network/',
    420: 'https://optimism-goerli.infura.io/v3/7a6077ec1ff04e70a21839f56d6a4ca1',
    1024: 'https://api-para.clover.finance',
    1285: 'https://rpc.api.moonriver.moonbeam.network/',
    1287: 'https://rpc.api.moonbase.moonbeam.network/',
    42161: 'https://arbitrum-mainnet.infura.io/v3/7a6077ec1ff04e70a21839f56d6a4ca1',
    42220: 'https://celo-mainnet.infura.io/v3/7a6077ec1ff04e70a21839f56d6a4ca1',
    43113: 'https://avalanche-fuji.infura.io/v3/7a6077ec1ff04e70a21839f56d6a4ca1',
    43114: 'https://avalanche-mainnet.infura.io/v3/7a6077ec1ff04e70a21839f56d6a4ca1',
    44787: 'https://celo-alfajores.infura.io/v3/7a6077ec1ff04e70a21839f56d6a4ca1',
    80001: 'https://polygon-mumbai.infura.io/v3/7a6077ec1ff04e70a21839f56d6a4ca1',
    421613: 'https://arbitrum-goerli.infura.io/v3/7a6077ec1ff04e70a21839f56d6a4ca1',
    1313161554: 'https://mainnet.aurora.dev',
    1313161555: 'https://testnet.aurora.dev',
    1666600000: 'https://api.harmony.one/',
    31337: 'http://localhost:8545'
  },
  CHAINID_TO_SUBGRAPH: {
    1: "https://api.thegraph.com/subgraphs/name/fanbaseeu/fanbase-network",
    5: "https://api.thegraph.com/subgraphs/name/cryptopanda430/fanbasegoerli",
    10: "https://api.thegraph.com/subgraphs/name/fanbaseeu/optimism-network",
    56: "https://api.thegraph.com/subgraphs/name/fanbaseeu/bnb-mainnet",
    137: "https://api.thegraph.com/subgraphs/name/fanbaseeu/polygon",
  },
  CHAINID_TO_GEYSER_ADDRESS: {
    1: "0x7016c6f4dba9a9a48a47a2fe73a1b9271432d1d5",
    5: "0xC883D4f7dAD93d4B7325Ed2B83eD56FD95E73c42",
    10: "0xfCDCBA599b2261f878CC9c244b2814FaE47F9b5B",
    56: "0x5370653C8A065cA4393Ffd3F892BD49178D85ced",
    137: "0x1d47536d368010A98343fF98Aa22BCB4B9d1a5BD",
  },
};

export default NETWORK_CONSTANT;
