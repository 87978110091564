import React, { useEffect, useState } from "react";
import { RampInstantSDK } from "@ramp-network/ramp-instant-sdk"
import { Modal } from "@nextui-org/react";
import { MoonPayBuyWidget } from "@moonpay/moonpay-react";

import { useWeb3React } from "@web3-react/core";
import { useWidget } from "../context/WidgetContext";
import { Util } from "../helper";
import { HiCog } from "react-icons/hi";

import { SETTING, SUPPORTED_CHAIN_IDS, NETWORK_CONSTANT, ENDPOINT, IMAGE, KEY_STORE } from "../constant";
import CustomConnectButton from "./CustomConnectButton";

interface TopSettingBarPropTypes {
     title: string;
}

const TopSettingBar: React.FC<TopSettingBarPropTypes> = ({ title }) => {
     const { activate, account, chainId, deactivate, library } = useWeb3React();
     const { slippage, setSlippage } = useWidget();

     const [settingModalOpened, setSettingModalOpened] = useState<boolean>(false);
     const [moonpayModalOpen, setMoonpayModalOpen] = useState<boolean>(false)

     const onSlippageChange = (_slippage: number) => {
          if (_slippage > SETTING.SWAP_SETTING.MAX_SLIPPAGE) {
               setSlippage(SETTING.SWAP_SETTING.MAX_SLIPPAGE);
          } else if (_slippage < SETTING.SWAP_SETTING.MIN_SLIPPAGE) {
               setSlippage(SETTING.SWAP_SETTING.MIN_SLIPPAGE);
          } else {
               setSlippage(_slippage);
          }
     };

     const handleActiveRamp = () => {
          new RampInstantSDK({
               hostAppName: 'Fanbase Aggregate Swap',
               fiatCurrency: 'USD',
               url: ENDPOINT.RAMP_TEST,
               hostLogoUrl: 'https://docs.ramp.network/img/logo-1.svg',
               hostApiKey: KEY_STORE.RAMP_TEST,
               defaultAsset:  'BTC',
               fiatValue: '10',
               variant: 'mobile',
               enabledFlows: ['ONRAMP', 'OFFRAMP']
          }).show()
     }

     const disconnectWallet = () => {
          deactivate();
     };

     useEffect(() => {
          (async () => {
               if (chainId && account && window.ethereum) {
                    if (SUPPORTED_CHAIN_IDS.indexOf(chainId) < 0) {
                         console.log(chainId);
                         try {
                              await library.provider.request({
                                   method: "wallet_switchEthereumChain",
                                   params: [{ chainId: Util.numberToHex(SUPPORTED_CHAIN_IDS[0]) }],
                              });
                         } catch (error) {
                              disconnectWallet();
                              try {
                                   await library.provider.request({
                                        method: "wallet_addEthereumChain",
                                        params: [
                                             {
                                                  chainId: Util.numberToHex(SUPPORTED_CHAIN_IDS[0]),
                                                  chainName:
                                                       NETWORK_CONSTANT.CHAINID_TO_NAME[SUPPORTED_CHAIN_IDS[0]],
                                                  rpcUrls: [
                                                       NETWORK_CONSTANT.CHAINID_TO_RPC[SUPPORTED_CHAIN_IDS[0]],
                                                  ],
                                             },
                                        ],
                                   });
                              } catch (error) {
                                   disconnectWallet();
                                   console.log(error);
                              }
                         }
                    }
               }
          })();
     }, [chainId]);

     return (
          <div className="flex justify-between w-full mb-2 pl-[5%]">
               <span className="font-bold sm:text-3xl md:text-xl 2xl:text-5xl">{title}</span>

               <div className="flex w-auto justify-between items-center">
                    <CustomConnectButton />

                    <div className="flex items-center gap-[5px]">
                         <button
                              className="w-[5dvw] min-w-[50px] h-[8dvh] flex items-center justify-center rounded-md bg-[#f3f7f9] text-gray-900 shadow-sm ring-1 ring-inset ring-[#e9edf0] hover:bg-gray-50"
                              onClick={() => setSettingModalOpened(true)}
                         >
                              <HiCog />
                         </button>
                         <button
                              className="w-[5dvw] min-w-[50px] h-[8dvh] flex items-center justify-center rounded-md bg-[#f3f7f9] text-gray-900 shadow-sm ring-1 ring-inset ring-[#e9edf0] hover:bg-gray-50"
                              onClick={() => setMoonpayModalOpen(true)}
                         >
                              <img src={IMAGE.RAMP} alt="Ramp Logo" className="w-7" />
                         </button>
                    </div>
               </div>
               <Modal fullScreen closeButton open={moonpayModalOpen} onClose={() => setMoonpayModalOpen(false)}>
                    <Modal.Body className="flex justify-center items-center">
                         <MoonPayBuyWidget
                              variant="embedded"
                              baseCurrencyCode="usd"
                              baseCurrencyAmount="100"
                              defaultCurrencyCode="eth"
                              onLogin={async () => console.log("Customer logged in")}
                              visible
                         />
                    </Modal.Body>
               </Modal>

               {settingModalOpened ? (
                    <div className="flex items-center justify-center absolute inset-0 z-10">
                         <div
                              className="flex items-center justify-center fixed inset-0 bg-black/50 z-10"
                              onClick={() => setSettingModalOpened(false)}
                         />
                         <div className="bg-white w-10/12 lg:w-[30%] mx-auto p-4 rounded-xl z-20">
                              <p className="text-xl font-bold">Settings</p>

                              <div>
                                   <p className="text-lg font-medium">Slippage</p>

                                   <input
                                        placeholder="0"
                                        value={slippage}
                                        onChange={(e) => onSlippageChange(parseFloat(e.target.value))}
                                        type="number"
                                        className="mt-2 w-full bg-[#f3f7f9] border border-black/10 mr-2 px-3 py-1 rounded-md"
                                   />

                                   <p className="text-sm my-2 text-center">or</p>

                                   <div className="flex items-center justify-between">
                                        <button
                                             type="button"
                                             onClick={() => setSlippage(0.5)}
                                             className="w-[32%] rounded-md bg-white px-8 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                             0.5
                                        </button>

                                        <button
                                             type="button"
                                             onClick={() => setSlippage(1)}
                                             className="w-[32%] rounded-md bg-white px-8 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                             1
                                        </button>

                                        <button
                                             type="button"
                                             onClick={() =>
                                                  setSlippage(SETTING.SWAP_SETTING.DEFAULT_SLIPPAGE)
                                             }
                                             className="w-[35%] rounded-md bg-white px-8 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                             Default
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </div>
               ) : null}
          </div>
     );
};

export default TopSettingBar;
