import type { tokenItem } from "../type";

interface NativeCoinType {
    [key: number]: tokenItem
}

const NATIVE_COIN: NativeCoinType = {
    1: {
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        wrapped: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        decimals: 18,
        name: 'Ethereum Coin',
        logo: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/color/eth.svg',
        symbol: 'ETH'
    },
    5: {
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        wrapped: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
        decimals: 18,
        name: 'Ethereum Coin',
        logo: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/color/eth.svg',
        symbol: 'ETH'
    },
    10: {
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        wrapped: '0x4200000000000000000000000000000000000006',
        decimals: 18,
        name: 'Optimism Coin',
        logo: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/color/eth.svg',
        symbol: 'ETH'
    },
    56: {
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        wrapped: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        decimals: 8,
        name: 'Binance Coin',
        logo: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/128/color/bnb.png',
        symbol: 'BNB'
    },
    122: {
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        wrapped: '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629',
        decimals: 8,
        name: 'FUSE',
        logo: 'https://icons.llamao.fi/icons/chains/rsz_fuse.jpg',
        symbol: 'FUSE'
    },
    137: {
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        wrapped: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
        decimals: 18,
        name: 'Polygon Coin',
        logo: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/128/color/matic.png',
        symbol: 'MATIC'
    },
    250: {
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        wrapped: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
        decimals: 18,
        name: 'FTM',
        logo: 'https://icons.llamao.fi/icons/chains/rsz_fantom.jpg',
        symbol: 'FTM'
    },
    43114: {
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        wrapped: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
        decimals: 18,
        name: 'AVAX',
        logo: 'https://icons.llamao.fi/icons/chains/rsz_avalanche.jpg',
        symbol: 'AVAX'
    },
    42161: {
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        wrapped: '0x912CE59144191C1204E64559FE8253a0e49E6548',
        decimals: 18,
        name: 'ETH',
        logo: 'https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg',
        symbol: 'ETH'
    },
    100: {
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        wrapped: '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d',
        decimals: 18,
        name: 'ETH',
        logo: 'https://icons.llamao.fi/icons/chains/rsz_xdai.jpg',
        symbol: 'ETH'
    },
    80001: {
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        decimals: 18,
        name: 'Polygon Coin',
        logo: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/128/color/matic.png',
        symbol: 'MATIC'
    },
    31337: {
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        decimals: 18,
        name: 'Ethereum Coin',
        logo: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/color/eth.svg',
        symbol: 'ETH'
    }
}

export default NATIVE_COIN