import React, { useState, useEffect } from "react";
import ImageLoader from "./ImageLoader";
import { Util } from "../helper";
import { metaData, contentType } from "../type";
import { IMAGE } from "../constant";

import 'react-jinke-music-player/assets/index.css'

interface NftContentPropTypes {
  uri: string;
}

const NftContent: React.FC<NftContentPropTypes> = ({ uri }) => {

  const [metaData, setMetaData] = useState<metaData>({});
  const [contentType, setContentType] = useState<contentType>(null)
  const [imageUrl, setImageUrl] = useState<string>(IMAGE.UNKNOWN)
  const [mediaUrl, setMediaUrl] = useState<string>("")
  const [audioUrl, setAudioUrl] = useState<string>("")

  const replaceSubstring = (supString: string) => {
    if (supString.indexOf('ipfs://') >= 0) {
      return supString.replace('ipfs://', 'https://ipfs.io/ipfs/')
    } else {
      return supString
    }
  }

  const clearAll = () => {
    setMetaData({})
    setContentType(null)
    setImageUrl(IMAGE.UNKNOWN)
    setMediaUrl("")
    setAudioUrl("")
  }

  const RenderNFT = () => {
    console.log('Content Type', contentType)
    console.log(metaData)

    switch (contentType) {
      case 'IMAGE':
        return (
          <ImageLoader
            crossOrigin="anonymous"
            className="h-1/2 w-1/2 mx-auto"
            src={imageUrl}
            alt={"img"}
            altSrc={IMAGE.IMAGE_ERROR}
          />
        )
      case 'MUSIC':
        return (
          <div className="audio_profile">
            <ImageLoader
              crossOrigin="anonymous"
              className="h-1/2 w-1/2 mx-auto"
              src={imageUrl}
              alt={"img"}
              altSrc={IMAGE.IMAGE_ERROR}
            />
            <audio crossOrigin="anonymous" controls loop>
              <source src={audioUrl}></source>
            </audio>
          </div>
        )
      case 'MEDIA':
        return (
          <video crossOrigin="anonymous" controls loop>
            <source src={mediaUrl}></source>
          </video>
        )

      default:
        return (
          <ImageLoader
            crossOrigin="anonymous"
            className="h-1/2 w-1/2 mx-auto"
            src={imageUrl}
            alt={"img"}
            altSrc={IMAGE.IMAGE_ERROR}
          />
        )
    }
  }

  useEffect(() => {
    (async () => {
      
      try {
        //Fetch metadata
        const metaData = await Util.parseMetadata(uri)
        setMetaData(metaData)

        //Check metadata is existing
        if (!metaData) {
          return
        }

        //Get properties from metadata
        if ((metaData?.media || metaData?.animation_url)) {
          setContentType('MEDIA')
          if (metaData?.media) {
            // media data is exist
            setMediaUrl(replaceSubstring(metaData?.media?.uri))
          } else {
            setMediaUrl(replaceSubstring(metaData?.animation_url))

            // animation url format is equal with audio formats
            if (
              Util.parseFileType(metaData?.animation_url) === '.mp3' ||
              Util.parseFileType(metaData?.animation_url) === '.ogg' ||
              Util.parseFileType(metaData?.animation_url) === '.wav'
            ) {
              setContentType('MUSIC')
              setAudioUrl(metaData?.animation_url)
            }
          }
          if (metaData?.image) {
            setImageUrl(replaceSubstring(metaData?.image))
          }
          else {
            setImageUrl(IMAGE.UNKNOWN)
          }
        } else if (metaData?.audio_url) {
          if (
            Util.parseFileType(metaData?.audio_url) === '.mp3' ||
            Util.parseFileType(metaData?.audio_url) === '.ogg' ||
            Util.parseFileType(metaData?.audio_url) === '.wav'
          ) {
            setContentType('MUSIC')
            setAudioUrl(replaceSubstring(metaData?.audio_url))
          }
          if (metaData?.image) {
            setImageUrl(replaceSubstring(metaData?.image))
          }
          else {
            setImageUrl(IMAGE.UNKNOWN)
          }
        }
        else {
          setContentType('IMAGE')
          if (metaData?.image) {
            setImageUrl(replaceSubstring(metaData?.image))
          }
          else {
            setImageUrl(IMAGE.UNKNOWN)
          }
        }
      } catch {
        clearAll()
      }
    })()
  }, [uri]);

  return (
    <RenderNFT />
  );
};

export default NftContent;
