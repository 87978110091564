const MESSAGE = {
    ERROR: {
        NO_PAIR: "Selected pair does not exist",
        CONTEXT: `Context can be used only in provider`,
        NOT_ERC1155CONTRACT: `Inputted address is not ERC1155 contract address`,
        FORCE_CONNECT: "Please connect your wallet",
        FORCE_SELECT_TOKEN: "Please select a token to send and one to receive",
        FORCE_INPUT_NUMERICAL_VALUE: "Please input only numerical amounts",
        FORCE_INPUT_AMOUNT: "Please input token amount",
        FAILED_FETCH_SWAP_DATA: "Failed to fetch swap data",
        FAILED_RUN_SWAP_DATA: "Failed to execute swap transaction",
        FORCE_SELECT_QUOTE: "Please select a quote from the list provided",
        INSUFFICIENT_LIQUIDITY: "Insufficient liquidity amount"
    },
    SUCCESS: {
        ERC1155CONTRACT_DETECTED: `ERC1155 Contract was detected`
    },
    INFO: {
        CHANGE_ACCOUNT: "To swap from a new account, you must also disconnect inside the wallet, then reconnect."
    },
    TITLE: {
        CHANGE_ACCOUNT: "Changing accounts?"
    }
}

export default MESSAGE