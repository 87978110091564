import React from "react";
import "@rainbow-me/rainbowkit/styles.css";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  polygonMumbai,
  bsc,
  bscTestnet,
  avalanche,
  goerli,
  optimismGoerli,
  avalancheFuji,
  arbitrumGoerli,
} from "@wagmi/chains";
import customChains from "../connector/customChain";

import { publicProvider } from "wagmi/providers/public";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    mainnet,
    goerli,
    optimism,
    bsc,
    bscTestnet,
    customChains.fuse,
    polygon,
    customChains.fantom,
    customChains.boba,
    customChains.moonriver,
    avalanche,
    arbitrum,
    customChains.celo,
    customChains.aurora,
    customChains.harmony,
    polygonMumbai,
    customChains.gnosis,
    optimismGoerli,
    customChains.moonbasealpha,
    avalancheFuji,
    customChains.alfajores,
    arbitrumGoerli,
    customChains.auroraTest,
    // customChains.hardhat
  ],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "Fanbase Widgets",
  projectId: "f171ba3735789feb54d01f87643003b7",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const ProviderWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>{children}</RainbowKitProvider>
    </WagmiConfig>
  );
};

export default ProviderWrapper;