import { axios5 } from "../lib";

export default class NftSwapAction {
    static async fetchPairList(chainId: number, toeknAddress: string, currencyAddress: string) {
        try {
            const response = await axios5.get(`/getPairList?chainId=${chainId}&tokenAddress=${toeknAddress}&currencyAddress=${currencyAddress}`)

            return response.data.data
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchNftBuyData(chainId: number, tokenAddress: string, currencyAddress: string, tokenId: number, buyAmount: any, appId: string | undefined) {
        try {
            const data = { chainId, tokenAddress, currencyAddress, tokenId, buyAmount }

            if (appId) {
                const response = await axios5.post(`/dataToBuyToken`, data, { headers: { 'Authorization': appId } })

                return response.data.data
            }
            else {
                const response = await axios5.post(`/dataToBuyToken`, data)

                return response.data.data
            }
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchNftSellData(chainId: number, tokenAddress: string, currencyAddress: string, tokenId: number, sellAmount: any, appId: string | undefined) {
        try {
            const data = { chainId, tokenAddress, currencyAddress, tokenId, sellAmount }

            if (appId) {
                const response = await axios5.post(`/dataToSellToken`, data, { headers: { 'Authorization': appId } })

                return response.data.data
            }
            else {
                const response = await axios5.post(`/dataToSellToken`, data)

                return response.data.data
            }
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchTokenToCurrencyPrice(chainId: number, tokenId: number, tokenAmount: any, exchangeAddress: string) {
        try {
            const params = { chainId, tokenId, tokenAmount, exchangeAddress }
            const response = await axios5.get(`/getTokenToCurrencyPrice`, { params })

            return response.data.data
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchCurrencyToTokenPrice(chainId: number | undefined, tokenId: number | undefined, tokenAmount: BigInt, exchangeAddress: string | undefined) {

        const params = { chainId, tokenId, tokenAmount, exchangeAddress }

        try {
            const response = await axios5.get(`/getCurrencyToTokenPrice`, { params })

            return response.data.data
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchBuyTransaction(chainId: number, currencyAddress: string, tokenId: number, buyAmount: any, currencyAmount: any, recipient: string, feeRecipient: string, feeAmount: number, exchangeAddress: string, appId: string | undefined) {
        try {
            const data = { chainId, currencyAddress, tokenId, buyAmount, currencyAmount, recipient, feeRecipient, feeAmount, exchangeAddress }

            if (appId) {
                const response = await axios5.post(`/buyToken`, data, { headers: { 'Authorization': appId } })

                return response.data.data
            }
            else {
                const response = await axios5.post(`/buyToken`, data)

                return response.data.data
            }
        }
        catch (error: any) {
            throw error
        }
    }

    static async fetchSellTransaction(chainId: number, tokenAddress: string, recipient: string, tokenId: number, tokenAmount: any, exchangeAddress: string, appId: string | undefined) {
        try {
            const data = { chainId, tokenAddress, recipient, tokenId, tokenAmount, exchangeAddress }

            if (appId) {
                const response = await axios5.post(`/sellToken`, data, { headers: { 'Authorization': appId } })

                return response.data.data
            }
            else {
                const response = await axios5.post(`/sellToken`, data)

                return response.data.data
            }
        }
        catch (error: any) {
            throw error
        }
    }
}