import React from "react";
import { MoonPayProvider } from "@moonpay/moonpay-react"

import _AggregationCryptoExchangeWidget from "./AggregationCryptoExchangeWidget";
import _CryptoExchangeWidget from "./CryptoExchangeWidget";
import _AggregationNftExchangeWidget from "./AggregationNftExchangeWidget";
import _NftExchangeWidget from "./NftExchangeWidget";

import WidgetProvider from "../context/WidgetContext";
import { Web3ReactProvider } from "@web3-react/core";
import getLibrary from "../connector/getLibrary";
import ProviderWrapper from "../components/ProviderWrapper";
import { KEY_STORE } from "../constant";

declare global {
    interface Window {
        ethereum?: any;
    }
}

interface ExchangeWidgetPropTypes {
    appId?: string;
    buyToken?: string;
    domain?: string;
    coverView?: "carousel" | "list"
}

export const AggregationCryptoExchangeWidget: React.FC<ExchangeWidgetPropTypes> = ({
    appId,
    buyToken,
    domain,
}) => {
    return (
        <MoonPayProvider apiKey={KEY_STORE.MOONPAY_TEST} debug>
            <WidgetProvider>
                <Web3ReactProvider getLibrary={getLibrary}>
                    <ProviderWrapper>
                        <_AggregationCryptoExchangeWidget appId={appId} buyToken={buyToken} domain={domain} />
                    </ProviderWrapper>
                </Web3ReactProvider>
            </WidgetProvider>
        </MoonPayProvider>
    );
};

export const CryptoExchangeWidget: React.FC<ExchangeWidgetPropTypes> = ({
    appId,
    buyToken,
    domain
}) => {
    return (
        <MoonPayProvider apiKey={KEY_STORE.MOONPAY_TEST} debug>
            <WidgetProvider>
                <Web3ReactProvider getLibrary={getLibrary}>
                    <ProviderWrapper>
                        <_CryptoExchangeWidget appId={appId} buyToken={buyToken} domain={domain} />
                    </ProviderWrapper>
                </Web3ReactProvider>
            </WidgetProvider>
        </MoonPayProvider>
    );
};

export const AggregationNftExchangeWidget: React.FC<ExchangeWidgetPropTypes> = ({
    appId,
    buyToken,
    domain,
    coverView
}) => {
    return (
        <MoonPayProvider apiKey={KEY_STORE.MOONPAY_TEST} debug>
            <WidgetProvider>
                <Web3ReactProvider getLibrary={getLibrary}>
                    <ProviderWrapper>
                        <_AggregationNftExchangeWidget appId={appId} buyToken={buyToken} domain={domain} coverView={coverView} />
                    </ProviderWrapper>
                </Web3ReactProvider>
            </WidgetProvider>
        </MoonPayProvider>
    );
};

export const NftExchangeWidget: React.FC<ExchangeWidgetPropTypes> = ({
    appId,
    buyToken,
    domain,
    coverView
}) => {
    return (
        <MoonPayProvider apiKey={KEY_STORE.MOONPAY_TEST} debug>
            <WidgetProvider>
                <Web3ReactProvider getLibrary={getLibrary}>
                    <ProviderWrapper>
                        <_NftExchangeWidget appId={appId} buyToken={buyToken} domain={domain} coverView={coverView} />
                    </ProviderWrapper>
                </Web3ReactProvider>
            </WidgetProvider>
        </MoonPayProvider>
    );
};