import React from 'react'

import type { tokenItem } from '../type';

interface TokenListItemPropType {
    address: string;
    name: string;
    symbol: string;
    logo: string;
    decimals: number;
    onClick: (token: tokenItem) => void
}

const TokenListItem: React.FC<TokenListItemPropType> = (props) => {
    const { address, name, symbol, decimals, logo, onClick } = props

    return (
        <button 
            className='w-full flex items-center p-2 cursor-pointer rounded-lg hover:bg-[#f3f7f9]'
            onClick={() => onClick({ address, name, symbol, decimals, logo })} >
            <img loading='lazy' src={logo} className='w-8 h-8 rounded-full'/>

            <div className='flex flex-col items-start ml-2'>
                <p className='font-bold'>{name}</p>
                <p className='text-sm font-medium'>{symbol}</p>
            </div>
        </button>
    )
}

export default TokenListItem