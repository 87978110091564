import React, { useState } from 'react'
import { Container, Text, Row, Grid, Col, Divider } from '@nextui-org/react'
import { Util } from '../helper'
import { IMAGE } from '../constant'

import { TbArrowBackUp } from 'react-icons/tb'

import type { OwnedNft } from 'alchemy-sdk'

import "../style/flipCard.css"

interface NFTGalleryItemProps {
    nft: OwnedNft
    onTokenImport: (tokenAddress: string, tokenId: string) => void
}

const NFTGalleryItem: React.FC<NFTGalleryItemProps> = (({ nft, onTokenImport }) => {

    const [flipped, setFlipped] = useState<boolean>(false)

    const handleFlip = () => setFlipped(true)
    const handleReflip = () => setFlipped(false)

    return (
        <Container className='flip-card'>
            <Container className={flipped ? `flip-card-inner flipped-card-inner` : `flip-card-inner`}>
                <Container className='flip-card-front'>
                    <Grid>
                        <Row>
                            {
                                (nft.rawMetadata?.animation_url || nft.rawMetadata?.animation) ?
                                    <video className='nft-content' src={Util.replaceSubstring(nft.rawMetadata?.animation_url || nft.rawMetadata?.animation) || IMAGE.IMAGE_ERROR} poster={Util.replaceSubstring(nft.rawMetadata?.image || nft.rawMetadata?.image_url || nft.media[0].thumbnail)} controls loop /> :
                                    <img className='nft-content' src={Util.replaceSubstring(nft.rawMetadata?.image || nft.rawMetadata?.image_url || nft.media[0].thumbnail) || IMAGE.IMAGE_ERROR} />
                            }

                        </Row>
                        <Row className='flip-card-button'>
                            <Col>
                                <button
                                    className="w-[100%] h-8 flex items-center justify-center shrink-0 bg-[#0162d0] text-white px-3.5 py-2.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                                    onClick={handleFlip}
                                >
                                    Details
                                </button>
                            </Col>
                            <Col>
                                <button
                                    className="w-[100%] h-8 flex items-center justify-center shrink-0 bg-[#0162d0] text-white px-3.5 py-2.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                                    onClick={() => onTokenImport(nft.contract.address, nft.tokenId)}
                                >
                                    Buy
                                </button>
                            </Col>
                        </Row>
                    </Grid>
                </Container>
                <Container className='flip-card-back'>
                    <Grid.Container>
                        <Grid xs={12} justify='flex-end'>
                            <a
                                href='#'
                                onClick={handleReflip}
                            >
                                <TbArrowBackUp size={20} />
                            </a>
                        </Grid>
                    </Grid.Container>
                    <Grid.Container>
                        <Grid xs={12} justify='center'>
                            <Text h1 size={17} weight='bold'>
                                {nft.contract.name || nft.title}
                            </Text>
                        </Grid>
                    </Grid.Container>
                    <Grid.Container style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                        <Row>
                            <Text size={12} weight='bold' >Name:&nbsp;</Text><Text size={12} color='gray' >{nft.contract.name} </Text>
                        </Row>
                        <Row>
                            <Text size={12} weight='bold' >Symbol:&nbsp;</Text><Text size={12} color='gray'> {nft.contract.symbol} </Text>
                        </Row>
                        <Row>
                            <Text size={12} weight='bold' >Token ID:&nbsp;</Text><Text size={12} color='gray' >{nft.tokenId} </Text>
                        </Row>
                        <Row>
                            <Text size={12} weight='bold' >Contract: <a target='_blank' className='address-link' href={`https://blockscan.com/address/${nft.contract.address}`}>{nft.contract.address}</a></Text>
                        </Row>
                        <Row>
                            <Text size={12} weight='bold' >Creator: <a target='_blank' className='address-link' href={`https://blockscan.com/address/${nft.contract.contractDeployer}`}>{nft.contract.contractDeployer}</a></Text>
                        </Row>
                        <Row>
                            <Divider hidden color='default' height={0.5} style={{ marginTop: 10 }} />
                        </Row>
                        <Row>
                            <Grid>
                                <Row justify='center'>
                                    <Text size={12} weight='bold' >Raw Metadata</Text>
                                </Row>
                                <Row>
                                    <code className='metadata-area' lang='javascript'>
                                        {JSON.stringify(nft.rawMetadata, null, 4)}
                                    </code>
                                </Row>
                            </Grid>
                        </Row>
                    </Grid.Container>
                </Container>
            </Container>
            {/* <Card.Footer>
                <button
                    className="w-middle flex mx-auto rounded-md bg-white px-6 py-2.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-100 hover:bg-gray-50"
                >
				    Import
			    </button>
            </Card.Footer> */}
        </Container>
    )
});

export default NFTGalleryItem