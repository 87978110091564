import axios from "axios";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { NETWORK_CONSTANT, NATIVE_COIN } from "../constant";
import { ERC20_ABI } from "../abi";
import { uniswapTokenItem } from "../type";

const VERSION = "v1";
const ENDPOINT = "https://api.fanbase.io/";
const UNISWAP_TOKEN_LIST = "https://tokens.uniswap.org";

export const getQuote = async (
	chainId: number,
	inputAddress: string,
	outputAddress: string,
	amount: number
) => {
	const { data } = await axios.get(
		`${ENDPOINT}${VERSION}/quotes?chainId=${chainId}&inputAddress=${inputAddress}&outputAddress=${outputAddress}&inputAmount=${amount}`
	);

	data[0].toTokenAmount = new BigNumber(data[0].toTokenAmount.hex).toNumber();

	console.log(data[0]);

	return { quote: data[0] };
};

export const getSwapTransaction = async (
	chainId: number,
	inputAddress: string,
	outputAddress: string,
	inputAmount: number,
	fromAddress: string,
	slippage: number,
	dexName: string
) => {
	const { data } = await axios({
		method: "POST",
		url: `${ENDPOINT}${VERSION}/swap`,
		data: {
			chainId,
			inputAddress,
			outputAddress,
			fromAddress,
			inputAmount,
			slippage,
			dexName,
		},
	});

	return data.data;
};

export const getApproveTransaction = async (
	chainId: number,
	inputAddress: string,
	outputAddress: string,
	inputAmount: number,
	dexName: string
) => {
	const { data } = await axios({
		method: "POST",
		url: `${ENDPOINT}${VERSION}/approve`,
		data: {
			chainId,
			inputAddress,
			outputAddress,
			inputAmount,
			dexName,
		},
	});

	return data.data;
};

export const isContractAddress = async (chainId: number, address: string) => {
	const rpc_url: string = NETWORK_CONSTANT.CHAINID_TO_RPC[chainId];
	const provider = new ethers.JsonRpcProvider(rpc_url)
	try {
		const code = await provider.getCode(address)
		if (code !== '0x') return true
		else return false
	} catch {
		return false
	}
}

export const getTokenDetail = async (address: string, chainId: number) => {

	if (!(await isContractAddress(chainId, address))) {
		throw new Error("Invalid contract address")
	}

	try {
		const rpc_url: string = NETWORK_CONSTANT.CHAINID_TO_RPC[chainId];
		const provider = new ethers.JsonRpcProvider(rpc_url);
		const contract = new ethers.Contract(address, ERC20_ABI, provider);
		const networkOrigin = (await provider.getNetwork()).name;
		const logoURI = `https://assets-cdn.trustwallet.com/blockchains/${networkOrigin}/assets/${address}/logo.png`;

		const [name, symbol, decimals] = await Promise.all([
			contract.name() || contract._name(),
			contract.symbol() || contract._symbol(),
			contract.decimals() || contract._decimals(),
		]);
		return { chainId, address, name, symbol, decimals, logoURI };
	}
	catch (error) {
		throw error
	}
};

export const getTokenBalance = async (chainId: number, address: string, account: string) => {
	if(address.toLowerCase() === NATIVE_COIN[chainId].address.toLowerCase()) {
		const rpc_url: string = NETWORK_CONSTANT.CHAINID_TO_RPC[chainId];
		const provider = new ethers.JsonRpcProvider(rpc_url);

		try {
			const balance = await provider.getBalance(account)
			return balance
		}
		catch(error) {
			console.error(error)
			throw error
		}
	}

	try {
		const rpc_url: string = NETWORK_CONSTANT.CHAINID_TO_RPC[chainId];
		const provider = new ethers.JsonRpcProvider(rpc_url);
		const contract = new ethers.Contract(address, ERC20_ABI, provider);

		const balance = await contract.balanceOf(account);
		return balance

	}
	catch (error) {
		console.error(error)
		throw error
	}
}

export const getTokenList = async (chainId: number | undefined) => {
	try {
		const response = await axios.get(UNISWAP_TOKEN_LIST, 
			// { 
			// 	headers: { 
			// 		'Access-Control-Allow-Origin': '*',
			// 		// 'Access-Control-Allow-Headers': '*',
			// 		'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT'
			// 	} 
			// }
		);
		const tokens: Array<uniswapTokenItem> = response.data.tokens;
		const list = tokens.filter((token) => token.chainId === chainId);
		return list;
	} catch {
		return [];
	}
};
