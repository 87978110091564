import { Chain } from '@rainbow-me/rainbowkit';
import { IMAGE } from '../constant';

const hardhat: Chain = {
    id: 31337,
    name: 'Hardhat',
    network: 'hardhat',
    iconUrl: IMAGE.NETWORK.HARDHAT,
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'Hardhat Ethereum',
        symbol: 'ETH',
    },
    rpcUrls: {
        public: { http: ['http://localhost:8545', 'http://127.0.0.1:8545'] },
        default: { http: ['http://localhost:8545', 'http://127.0.0.1:8545'] },
    },
    testnet: true,
};

const aurora: Chain = {
    id: 1313161554 ,
    name: 'Aurora Mainnet',
    network: 'auroramainnet',
    iconUrl: IMAGE.NETWORK.AURORA,
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'Aurora Ether',
        symbol: 'ETH',
    },
    rpcUrls: {
        public: { http: ['https://mainnet.aurora.dev', 'https://endpoints.omniatech.io/v1/aurora/mainnet/public'] },
        default: { http: ['https://mainnet.aurora.dev', 'https://endpoints.omniatech.io/v1/aurora/mainnet/public'] },
    },
    testnet: false,
}

const auroraTest: Chain = {
    id: 1313161555 ,
    name: 'Aurora Testnet',
    network: 'auroratestnet',
    iconUrl: IMAGE.NETWORK.AURORA,
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'Aurora Ether',
        symbol: 'ETH',
    },
    rpcUrls: {
        public: { http: ['https://endpoints.omniatech.io/v1/aurora/testnet/public', 'https://testnet.aurora.dev'] },
        default: { http: ['https://endpoints.omniatech.io/v1/aurora/testnet/public', 'https://testnet.aurora.dev'] },
    },
    testnet: true,
}

const fuse: Chain = {
    id: 122 ,
    name: 'Fuse Mainnet',
    network: 'fusemainnet',
    iconUrl: IMAGE.NETWORK.FUSE,
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'FUSE',
        symbol: 'FUSE',
    },
    rpcUrls: {
        public: { http: ['https://rpc.fuse.io', 'https://fuse-mainnet.chainstacklabs.com'] },
        default: { http: ['https://rpc.fuse.io', 'https://fuse-mainnet.chainstacklabs.com'] },
    },
    testnet: false,
}

const fantom: Chain = {
    id: 250 ,
    name: 'Fantom Mainnet',
    network: 'fantommainnet',
    iconUrl: IMAGE.NETWORK.FANTOM,
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'FTM',
        symbol: 'FTM',
    },
    rpcUrls: {
        public: { http: ['https://fantom.blockpi.network/v1/rpc/public', 'https://rpcapi.fantom.network'] },
        default: { http: ['https://fantom.blockpi.network/v1/rpc/public', 'https://rpcapi.fantom.network'] },
    },
    testnet: false,
}

const boba: Chain = {
    id: 288 ,
    name: 'Boba Mainnet',
    network: 'bobamainnet',
    iconUrl: IMAGE.NETWORK.BOBA,
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'Boba Ether',
        symbol: 'ETH',
    },
    rpcUrls: {
        public: { http: ['https://replica.boba.network', 'https://mainnet.boba.network'] },
        default: { http: ['https://replica.boba.network', 'https://mainnet.boba.network'] },
    },
    testnet: false,
}

const moonriver: Chain = {
    id: 1285,
    name: 'Moonriver Mainnet',
    network: 'moonrivermainnet',
    iconUrl: IMAGE.NETWORK.MOONRIVER,
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'MOVR',
        symbol: 'MOVR',
    },
    rpcUrls: {
        public: { http: ['https://rpc.api.moonriver.moonbeam.network', 'https://moonriver-rpc.dwellir.com'] },
        default: { http: ['https://rpc.api.moonriver.moonbeam.network', 'https://moonriver-rpc.dwellir.com'] },
    },
    testnet: false,
}

const celo: Chain = {
    id: 42220,
    name: 'Celo Mainnet',
    network: 'celomainnet',
    iconUrl: IMAGE.NETWORK.CELO,
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'CELO',
        symbol: 'CELO',
    },
    rpcUrls: {
        public: { http: ['https://rpc.ankr.com/celo', 'https://forno.celo.org'] },
        default: { http: ['https://rpc.ankr.com/celo', 'https://forno.celo.org'] },
    },
    testnet: false,
}

const harmony: Chain = {
    id: 1666600000,
    name: 'Harmony Mainnet',
    network: 'harmonymainnetshard0',
    iconUrl: IMAGE.NETWORK.HARMONY,
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'ONE',
        symbol: 'ONE',
    },
    rpcUrls: {
        public: { http: ['https://api.harmony.one', 'https://rpc.ankr.com/harmony'] },
        default: { http: ['https://api.harmony.one', 'https://rpc.ankr.com/harmony'] },
    },
    testnet: false,
}

const gnosis: Chain = {
    id: 100,
    name: 'Gnosis Mainnet',
    network: 'gnosismainnet',
    iconUrl: IMAGE.NETWORK.GNOSIS,
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'XDAI',
        symbol: 'XDAI',
    },
    rpcUrls: {
        public: { http: ['https://rpc.ankr.com/gnosis', 'https://gnosis.blockpi.network/v1/rpc/public'] },
        default: { http: ['https://rpc.ankr.com/gnosis', 'https://gnosis.blockpi.network/v1/rpc/public'] },
    },
    testnet: false,
}

const moonbasealpha: Chain = {
    id: 1287,
    name: 'Moonbase Alpha Mainnet',
    network: 'moonbasealphamainnet',
    iconUrl: IMAGE.NETWORK.MOONBASE,
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'DEV',
        symbol: 'DEV',
    },
    rpcUrls: {
        public: { http: ['https://moonbeam-alpha.api.onfinality.io/public', 'https://moonbase-alpha.public.blastapi.io'] },
        default: { http: ['https://moonbeam-alpha.api.onfinality.io/public', 'https://moonbase-alpha.public.blastapi.io'] },
    },
    testnet: false,
}

const alfajores: Chain = {
    id: 44787,
    name: 'Celo Alfajores Testnet',
    network: 'celoalfajores',
    iconUrl: IMAGE.NETWORK.CELO,
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'CELO',
        symbol: 'CELO',
    },
    rpcUrls: {
        public: { http: ['https://alfajores-forno.celo-testnet.org'] },
        default: { http: ['https://alfajores-forno.celo-testnet.org'] },
    },
    testnet: true,
}

const customChains = {
    hardhat,
    alfajores,
    moonbasealpha,
    moonriver,
    celo,
    harmony,
    gnosis,
    boba,
    fuse,
    fantom,
    aurora,
    auroraTest
}

export default customChains