import { ethers } from "ethers"

const POLLING_INTERVAL = 12000

const getLibrary = (provider: any) => {
    const library = new ethers.BrowserProvider(provider)
    library.pollingInterval = POLLING_INTERVAL

    return library
}

export default getLibrary