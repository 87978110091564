import React from "react";
import { NftExchangeWidget } from "../components/widget";

interface WidgetFrameProps {
    width?: string;
    height?: string;
    appId?: string;
    buyToken?: string;
    domain?: string;
    coverView?: "carousel" | "list"
}

const NftExchangeWidgetFrame: React.FC<WidgetFrameProps> = ({ width, height, appId, buyToken, domain, coverView }) => {
    return (
        <div className={`bg-[#ffffff] w-[${!!width ? width : '100%'}] h-[${!!height ? height : '100dvh'}]`}>
            <div className="flex flex-col w-full h-[100dvh]">
                <NftExchangeWidget appId={appId} buyToken={buyToken} domain={domain} coverView={coverView} />
            </div>
        </div>
    );
};

export default NftExchangeWidgetFrame;