import React, {useState, useEffect} from 'react'

interface ImageLoaderPropTypes {
    src?: string;
    altSrc?: any;
    alt?: string;
    className?: string | any;
    crossOrigin?: 'anonymous' | 'use-credentials';
}

const ImageLoader: React.FC<ImageLoaderPropTypes> = ({src, altSrc, className, alt, crossOrigin}) => {
    
    const [source, setSource] = useState(src)

    const handleError = () => {
        setSource(altSrc)
    }
    
    useEffect(() => {
        setSource(src)
    }, [src])

    return (
        <img src={source} className={className} alt={alt} crossOrigin={crossOrigin} onError={handleError} />
    )
}

export default ImageLoader