import { useRoutes, useSearchParams } from "react-router-dom"
import MainLayout from "../layouts/main"

// ** Import frames
import AggregationCryptoExchangeWidgetFrame from "../pages/AggregationCryptoExchangeWidget"
import AggregationNftExchangeWidgetFrame from "../pages/AggregationNftExchangeWidget"
import CryptoExchangeWidgetFrame from "../pages/CryptoExchangeWidget"
import NftExchangeWidgetFrame from "../pages/NftExchangeWidget"
import InvalidWidgetFrame from "../pages/InvalidWidget"

const Router = () => {

    const [ searchParams ] = useSearchParams()

    const width = searchParams.getAll('width')[0]
    const height = searchParams.getAll('height')[0]
    
    const buyToken = searchParams.getAll('buy_token')[0]

    const accessKey = searchParams.getAll('api_key')[0]
    const domain = searchParams.getAll('sup_domain')[0]
    const chainId = searchParams.getAll('chain_id')[0]

    const coverView = searchParams.getAll('cover_view')[0]

    return (
        useRoutes([
            {
                path: "/",
                element: <MainLayout />,
                children: [
                    {
                        path: "/agg20-swap",
                        element: <AggregationCryptoExchangeWidgetFrame width={width} height={height} appId={accessKey} buyToken={buyToken} domain={domain} />
                    },
                    {
                        path: "/agg1155-swap",
                        element: <AggregationNftExchangeWidgetFrame width={width} height={height} appId={accessKey} buyToken={buyToken} domain={domain} coverView={coverView === "carousel" ? "carousel" : ( coverView === "list" ? "list" : undefined )} />
                    },
                    {
                        path: "/pure20-swap",
                        element: <CryptoExchangeWidgetFrame width={width} height={height} appId={accessKey} buyToken={buyToken} domain={domain} />
                    },
                    {
                        path: "/pure1155-swap",
                        element: <NftExchangeWidgetFrame width={width} height={height} appId={accessKey} buyToken={buyToken} domain={domain} coverView={coverView === "carousel" ? "carousel" : ( coverView === "list" ? "list" : undefined )} />
                    },
                    {
                        path: "/*",
                        element: <InvalidWidgetFrame />
                    },
                ]
            }
        ])
    )
}

export default Router