// Tool Icon
import METAMASK from '../image/metamask.png';
import WALLETCONNECT from '../image/walletconnect.png';
import NOPREVIEW from '../image/nopreview.png';
import UNKNOWN from '../image/unknownerc1155.png';
import LOGO from '../image/logo.png';
import IMAGE_ERROR from '../image/imageerror.png';
import GREEN_CIRGLE from '../image/circle.png';
import RAMP from '../image/ramp-logo.png'

//Network Icon
import AURORA from '../image/network/aurora.png'
import BOBA from '../image/network/boba.png'
import CELO from '../image/network/celo.png'
import FANTOM from '../image/network/fantom.png'
import FUSE from '../image/network/fuse.png'
import GNOSIS from '../image/network/gnosis.png'
import HARDHAT from '../image/network/hardhat.png'
import HARMONY from '../image/network/harmony.png'
import MOONBASE from '../image/network/moonbase.png'
import MOONRIVER from '../image/network/moonriver.png'
import ETHEREUM from '../image/network/ethereum.png'

const IMAGE = {
    METAMASK,
    WALLETCONNECT,
    NOPREVIEW,
    UNKNOWN,
    LOGO,
    IMAGE_ERROR,
    GREEN_CIRGLE,
    RAMP,
    NETWORK: {
        ETHEREUM,
        AURORA,
        BOBA,
        CELO,
        FANTOM,
        FUSE,
        GNOSIS,
        HARDHAT,
        HARMONY,
        MOONBASE,
        MOONRIVER
    }
}

export default IMAGE